export const en = {
  translation: {
    'Visits register': 'Visitors Log',
    yes: 'yes',
    no: 'no',
    next: 'next',
    back: 'back',
    enter: 'enter',
    exit: 'exit',
    save: 'Save',
    remove: 'Remove',
    actions: 'Actions',
    close: 'Close',
    nif: 'ID',
    nifRequired: 'DNI is required',
    qrHint: 'You can use QR if you prefer so',
    visitConfirmation: 'Your visit has been successfully saved',
    visitConfirmationError: 'There was an error during saving the visit',
    saving: 'Saving',
    visitor: 'visitor',
    email: 'Email',
    reason: 'Reason',
    department: 'Department',
    questionaryTitle: 'specify if in your last two weeks',
    requiredField: 'Required field',
    formatIncorrect: 'Incorrect format',
    receiveRulesEmail: 'I want to receive the security rules by email',
    authorize: 'Authorize',
    closeVisit: 'Close visit',
    manualExit: 'Manual',
    reprintTicket: 'Print the label',
    downloadExcel: 'Download Excel',
    createVisit: 'Create visit',
    nameAndSurname: 'Name and surname',
    name: 'Name',
    surname: 'Surname',
    company: 'Company',
    employee: 'Visiting person',
    employees: 'Employees',
    employeeName: 'Name of the visiting person',
    employeeEmail: 'Email of the visiting person',
    employeePhone: 'Phone number or extension',
    visitComment: 'Additional comment',
    comment: 'Comment',
    activeVisits: 'Active visits',
    visitsToday: 'Visits today',
    clients: 'Clients',
    goodbye: 'Thank you for your visit',
    goToVisitorsLog: 'Go to Visitors Log',
    loginAs: 'Login',
    logout: 'Logout',
    health: 'Health',
    exitAuthorized: 'Exit authorized',
    exitNotAuthorized: 'Your exit is not authorized',
    exitAuthorizationOptions: 'Exit authorization options',
    exitAuthorizationOptionScan: 'The visited person should scan the employee card',
    exitAuthorizationOptionAdmin: 'Ask for the authorization at the entrance',
    gdprLink: 'Organic Law About Protection of Personal Data. <1>Read more<1>.',
    gdprTitle: 'According to Organic Law About Protection of Personal Data we inform that:',
    gdprCondition1:
      'The data presented in this form are required to your authorization and will be stored in our database for exlusive use for authorized people only.',
    gdprCondition2:
      'You are entering the area covered with video and image footage while keeping you the right to utilize the right to access, rectification, cancelation and oposition sending a written request to the corresponding department.',
    printingTicket: 'Pick up your identification label at the window.',
    placeTicket: 'Place the label on a visible place.',
    emptyTable: 'No records',
    paginationOf: 'of',
    filterTable: 'Filter',
    reset: 'Reset',
    toFilter: 'Filtar',
    log: 'Log',
    closeVisits: 'Close visits',
    closeVisitsConfirm: 'Are you sure you want to close the open visits?',
    understood: 'Understood',
    questionaryTitleUserData: 'EXPLICIT CONSENT OF DATA USAGE',
    details: 'Details',
    addEmployee: 'Add employee',
    deleteEmployee: 'Delete employee',
    editEmployee: 'Edit employee',
  },
};
