import React from 'react';
import { captureException } from '@sentry/react';
import { db, useRealtimeQuery, useStorage } from 'firebase.js';
import moment from 'moment';
import { useCollectionDataOnce } from 'react-firebase-hooks/firestore';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'react-use';
import * as yup from 'yup';
import { DNI_LENGTH_THRESHOLD } from 'components/Client/clientConsts';
import { useClient } from 'components/Client/clientHooks';
import { VisitContext } from 'components/Visit/VisitProvider';
import { createPhoneCall, createPrintTicketJob } from 'components/Visit/visitUtils';
import firebase from 'firebase/compat/app';
import { retry } from 'utils/async';
import { deviceLog, LOG_EVENT_TYPES } from 'utils/deviceUtils';
import { sendEmail } from 'utils/email';
import { ServiceRequestExternalError } from 'utils/error';
import { fetchProxy } from 'utils/networkUtils';
import { removeEmptyProps } from 'utils/objectUtils';
import { getHash, emptyStringToNull } from 'utils/stringUtils';
import { createEmailForEmployee, createEmailForVisitor, createEmailForVisitorExit } from './visitUtils';

export const useVisitForm = () => {
  return React.useContext(VisitContext);
};

export const useCreateVisit = () => {
  const { queryRef } = useClient();
  return React.useCallback(
    async (visit) => {
      const visitId = `${moment().format('YYYYMMDD-HHmmss')}-${getHash()}`;

      try {
        deviceLog(LOG_EVENT_TYPES.saveVisit, { nif: visit.nif.toUpperCase() });
        const newVisit = {
          ...removeEmptyProps(visit),
          nif: visit.nif.toUpperCase(),
          companyUpperCase: visit.company
            .toUpperCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, ''),
          employeeNameUpperCase: visit.employeeName
            .toUpperCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, ''),
          nameUpperCase: visit.name
            .toUpperCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, ''),
          surnameUpperCase: visit.surname
            .toUpperCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, ''),
          entry: firebase.firestore.FieldValue.serverTimestamp(),
          exit: false,
        };
        await queryRef.collection('accesses').doc(visitId).set(newVisit);
        return {
          result: 'ok',
          data: {
            id: visitId,
            ...newVisit,
          },
        };
      } catch (e) {
        captureException(e);
        console.error('Cannot save to Firestore', e);
        return {
          result: 'error',
          error: e,
        };
      }
    },
    [queryRef]
  );
};

export const useSendEmails = () => {
  const { client } = useClient();
  return React.useCallback(
    (visit, rulesPdfUrl, exit) => {
      if (visit.employeeEmail) {
        // TODO: remove authorize link when exitAuthorization is false
        sendEmail(createEmailForEmployee(client, visit.employeeEmail, visit));
      }
      if (visit.receiveRulesEmail && visit.email && !exit) {
        sendEmail(createEmailForVisitor(visit, client.name, rulesPdfUrl));
      }
      if (exit) {
        sendEmail(createEmailForVisitorExit(visit, client.name));
      }
    },
    [client]
  );
};

export const usePhoneCall = () => {
  const { client } = useClient();

  return React.useCallback(
    async (visit) => {
      let url = 'https://utils.soincon.es/PhoneCallService';
      if (window.Cypress) {
        url = 'http://localhost/PhoneCallService';
      }
      const phoneCall = createPhoneCall(client, visit);
      if (!phoneCall) return;

      const attempts = 10;
      try {
        const { attempt } = await retry(() => serviceRequest(url, JSON.stringify(phoneCall)), attempts, {
          onBeforeRetry: (attempt) => {
            deviceLog(LOG_EVENT_TYPES.retry, { url, attempt, proxy: true });
          },
          doNotRetryErrors: [ServiceRequestExternalError],
        });
        deviceLog(LOG_EVENT_TYPES.phoneCall, { result: 'OK', attempt, body: JSON.stringify(phoneCall) });
      } catch (error) {
        console.error(error);
        captureException(error);
        deviceLog(LOG_EVENT_TYPES.phoneCall, {
          error: error.message,
          body: JSON.stringify(phoneCall),
          attempts,
        });
      }
    },
    [client]
  );
};

export const usePrintVisit = () => {
  const { client } = useClient();
  return React.useCallback(
    async (visit) => {
      let url = 'https://utils.soincon.es/PrinterService';
      if (window.Cypress) {
        url = 'http://localhost/PrinterService';
      }
      const job = createPrintTicketJob(client, visit);
      if (!job) return;

      const attempts = 10;
      try {
        const { attempt } = await retry(() => serviceRequest(url, JSON.stringify(job)), attempts, {
          onBeforeRetry: (attempt) => {
            deviceLog(LOG_EVENT_TYPES.retry, { url, attempt, proxy: true });
          },
          doNotRetryErrors: [ServiceRequestExternalError],
        });
        deviceLog(LOG_EVENT_TYPES.printVisit, { result: 'OK', attempt, body: JSON.stringify(job) });
      } catch (error) {
        console.error(error);
        captureException(error);
        deviceLog(LOG_EVENT_TYPES.printVisit, {
          error: error.message,
          body: JSON.stringify(job),
          attempts,
        });
      }
    },
    [client]
  );
};

const serviceRequest = async (url, body) => {
  try {
    const response = await fetchProxy(url, {
      method: 'POST',
      body: body,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });
    if (response.status !== 200) throw new Error(response.status);
    if (!response.data) throw new Error(`No response data received`);
    if (response.data.successful !== 'true') throw new ServiceRequestExternalError(`successful: false`);

    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const useRulesPdf = () => {
  const { client } = useClient();
  const { i18n } = useTranslation();
  const rulesPdfName = client.rulesFilename && client.rulesFilename[i18n.language];
  const rulesPdf = useStorage(`clients/${client.id}/${rulesPdfName}`, { enabled: !!rulesPdfName, raw: true });
  const rulesPdfFallbackName = client.rulesFilename && client.rulesFilename[Object.keys(client.rulesFilename)[0]];
  const rulesPdfFallback = useStorage(`clients/${client.id}/${rulesPdfFallbackName}`, {
    enabled: !rulesPdfName && !!rulesPdfFallbackName,
  });

  return rulesPdfName ? rulesPdf : rulesPdfFallback;
};

const PHONE_NUMBER_LENGTH = 11; // 34 123 456 789

export const useValidationSchema = () => {
  const { isFieldRequired, getFieldValidation } = useClient();
  const filter = React.useCallback(
    (filterFields) => {
      const schema = {
        nif: yup.string(),
        photo: yup.string(),
        name: yup.string(),
        surname: yup.string(),
        company: yup.string(),
        email: yup.string().email(),
        reason: yup.string(),
        department: yup.string(),
        comment: yup.string(),
        employeeName: yup.string(),
        employeeEmail: yup.string().email(),
        employeePhone: yup
          .number()
          .test('format', `Must be exactly ${PHONE_NUMBER_LENGTH} characters`, (employeePhone) => {
            const digits = (employeePhone || '').toString().length;
            if (digits === 0) return true;
            return digits === PHONE_NUMBER_LENGTH;
          })
          .transform(emptyStringToNull)
          .nullable(),
        receiveRulesEmail: yup.bool(),
      };
      Object.keys(schema)
        .filter((field) => {
          if (!filterFields) return true;
          return filterFields.includes(field);
        })
        .forEach((field) => {
          if (isFieldRequired(field)) {
            schema[field] = schema[field].required();
          }
          Object.entries(getFieldValidation(field) || {}).forEach(([rule, value]) => {
            if (rule === 'pattern') {
              schema[field] = schema[field].test('format', '', (v) => v.match(value));
            }
          });
        });
      return yup.object().shape(schema);
    },
    [isFieldRequired, getFieldValidation]
  );

  return {
    schema: filter(),
    filter,
  };
};

const queryOptions = { idField: 'id' };

export const useVisit = (options) => {
  const { visitForm } = useVisitForm();
  const nif = visitForm.watch('nif');
  const { client } = useClient();
  const [query, setQuery] = React.useState();
  const [nifInner, setNifInner] = React.useState();
  const [result, setResult] = React.useState();

  useDebounce(
    () => {
      if (nif.length < DNI_LENGTH_THRESHOLD) return;
      setNifInner(nif);
    },
    options.debounce || 0,
    [nif]
  );

  React.useEffect(() => {
    if (!nifInner) return;
    let query = db.collection(`clients/${client.id}/accesses`);
    query = query.where('nif', '==', nifInner);
    setQuery(query.orderBy('entry', 'desc').limit(1));
    deviceLog(LOG_EVENT_TYPES.searchPrefill, { nif: nifInner });
  }, [nifInner, client.id]);

  const data = useCollectionDataOnce(query, queryOptions);
  const docs = data[0];
  let visit = null;
  if (docs && docs[0]) {
    if (docs[0].surname === undefined) {
      const newVisit = docs[0];
      delete newVisit.name;
      visit = newVisit;
    } else {
      visit = docs[0];
    }
  }

  React.useEffect(() => {
    if (!query || !visit || !nifInner) {
      setResult();
      return;
    }
    if (result && visit.id === result.id) return;
    if (visit.nif !== nifInner) {
      return;
    }
    setResult(visit);
  }, [visit, result, query, nifInner]);

  // Reset visit when NIF is reset
  React.useEffect(() => {
    if (result && result.nif !== nif) {
      setQuery();
      setResult();
      setNifInner();
    }
  }, [nif, result]);

  return [result, data[1], data[2]];
};

export const useVisits = (params = {}) => {
  const { client } = useClient();
  let query = db.collection('clients').doc(client.id).collection('accesses');
  query = applyQueryFilter(query, params.where || {});
  query = query.orderBy('entry', 'desc');
  if (params.startAfter) {
    query = query.startAt(params.startAfter);
  }
  if (params.endBefore) {
    query = query.endAt(params.endBefore);
  }
  if (params.limit) {
    if (params.endBefore) {
      query = query.limitToLast(params.limit);
    } else {
      query = query.limit(params.limit);
    }
  }
  return useRealtimeQuery(query, accessesOptions);
};

export const useAllVisits = (params = {}) => {
  const { client } = useClient();
  let query = db.collection('clients').doc(client.id).collection('accesses');
  query = applyQueryFilter(query, params.where || {});
  query = query.orderBy('entry', 'desc');
  return useRealtimeQuery(query, accessesOptions);
};

export const applyQueryFilter = (query, filter) => {
  if (filter.today) {
    const today = { start: moment().startOf('day').toDate(), end: moment().endOf('day').toDate() };
    query = query.where('entry', '>', today.start).where('entry', '<', today.end);
  }
  if (filter.active) {
    query = query.where('exit', '==', false);
  }
  if (
    filter.nif &&
    !filter.today &&
    !filter.name &&
    !filter.company &&
    !filter.employeeName &&
    !filter.reason &&
    !filter.surname &&
    !filter.fromDate &&
    !filter.untilDate
  ) {
    // Firestore does not allow to use two inequality filters in one query (today + nif)
    // https://firebase.google.com/docs/firestore/query-data/queries#compound_queries

    // Need to include also a '<' filter to limit the result by the last characte of a string
    // https://stackoverflow.com/a/57290806/177079

    const end = filter.nif.replace(/.$/, (c) => String.fromCharCode(c.charCodeAt(0) + 1)).toUpperCase();
    query = query.where('nif', '>=', filter.nif.toUpperCase());
    query = query.where('nif', '<', end);
    query = query.orderBy('nif', 'asc');
  }
  if (
    filter.name &&
    !filter.nif &&
    !filter.today &&
    !filter.company &&
    !filter.employeeName &&
    !filter.reason &&
    !filter.surname &&
    !filter.fromDate &&
    !filter.untilDate
  ) {
    const end = filter.name.replace(/.$/, (c) => String.fromCharCode(c.charCodeAt(0) + 1)).toUpperCase();
    query = query.where('nameUpperCase', '>=', filter.name.toUpperCase());
    query = query.where('nameUpperCase', '<', end);
    query = query.orderBy('nameUpperCase', 'asc');
  }
  if (
    !filter.nif &&
    !filter.today &&
    filter.surname &&
    !filter.company &&
    !filter.name &&
    !filter.employeeName &&
    !filter.reason &&
    !filter.fromDate &&
    !filter.untilDate
  ) {
    const end = filter.surname.replace(/.$/, (c) => String.fromCharCode(c.charCodeAt(0) + 1)).toUpperCase();
    query = query.where('surnameUpperCase', '>=', filter.surname.toUpperCase());
    query = query.where('surnameUpperCase', '<', end);
    query = query.orderBy('surnameUpperCase', 'asc');
  }
  if (
    !filter.nif &&
    !filter.today &&
    !filter.name &&
    filter.company &&
    !filter.employeeName &&
    !filter.reason &&
    !filter.fromDate &&
    !filter.surname &&
    !filter.untilDate
  ) {
    const end = filter.company.replace(/.$/, (c) => String.fromCharCode(c.charCodeAt(0) + 1)).toUpperCase();
    query = query.where('companyUpperCase', '>=', filter.company.toUpperCase());
    query = query.where('companyUpperCase', '<', end);
    query = query.orderBy('companyUpperCase', 'asc');
  }
  if (
    !filter.nif &&
    !filter.today &&
    !filter.name &&
    !filter.company &&
    filter.employeeName &&
    !filter.surname &&
    !filter.reason &&
    !filter.fromDate &&
    !filter.untilDate
  ) {
    const end = filter.employeeName.replace(/.$/, (c) => String.fromCharCode(c.charCodeAt(0) + 1)).toUpperCase();
    query = query.where('employeeNameUpperCase', '>=', filter.employeeName.toUpperCase());
    query = query.where('employeeNameUpperCase', '<', end);
    query = query.orderBy('employeeNameUpperCase', 'asc');
  }
  if (
    !filter.nif &&
    !filter.today &&
    !filter.name &&
    !filter.company &&
    !filter.employeeName &&
    !filter.surname &&
    filter.reason &&
    !filter.fromDate &&
    !filter.untilDate
  ) {
    query = query.where('reason', '==', filter.reason);
    query = query.orderBy('reason', 'asc');
  }
  if (
    !filter.nif &&
    !filter.today &&
    !filter.name &&
    !filter.company &&
    !filter.employeeName &&
    !filter.surname &&
    !filter.reason &&
    filter.fromDate
  ) {
    query = query.where('entry', '>=', new Date(filter.fromDate));
  }
  if (
    !filter.nif &&
    !filter.today &&
    !filter.name &&
    !filter.company &&
    !filter.employeeName &&
    !filter.surname &&
    !filter.reason &&
    filter.untilDate
  ) {
    query = query.where('entry', '<', new Date(filter.untilDate));
  }
  return query;
};

const accessesOptions = {
  transform: ({ exitAuthorization, ...access }) => ({
    ...access,
    entry: access.entry.seconds * 1000,
    exit: access.exit && access.exit.seconds * 1000,
    exitAuthorizationTimestamp: exitAuthorization && exitAuthorization.createdTime.seconds * 1000,
    exitAuthorizationType: exitAuthorization && exitAuthorization.type,
  }),
  idField: 'id',
};

// Filters todays visits by another fields.
// It's used because Firestore cannot search for today && nif at once.
export const useTodaysVisitsFilter = (visitsToday, filter) => {
  const [result, setResult] = React.useState(null);

  React.useEffect(() => {
    // Filter only when nif && today
    if (!filter.nif || !filter.today) {
      setResult(null);
      return;
    }

    setResult(
      visitsToday.filter((today) => {
        return today.nif.toUpperCase().startsWith(filter.nif.toUpperCase());
      })
    );
  }, [filter.nif, filter.today, visitsToday]);

  return result;
};
