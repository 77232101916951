import React from 'react';
import { Typography } from '@material-ui/core';
import { captureException, setContext } from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { getClient } from 'components/Client/clientUtils';
import { ShadowLoginBar } from 'components/Client/ShadowLoginBar';
import { CustomDialog } from 'components/CustomDialog/CustomDialog';
import { DeviceControl } from 'components/Device/DeviceControl';
import { ThemeContext } from 'components/Theme/ThemeProvider';
import * as sessionReplay from 'utils/sessionReplay';
import { db, useRealtimeDoc } from '../../firebase';
import { useAuth } from '../Auth/authHooks';
import { CustomCircularProgress } from '../CustomCircularProgress/CustomCircularProgress';

const setSentryContext = setContext;

let reloadTimeout;

export const ClientProvider = (props) => {
  const { clientId, logout, shadowLogin } = useAuth();
  const { i18n } = useTranslation();
  const { setTheme } = React.useContext(ThemeContext);
  const [client, isLoading, clientError] = useRealtimeDoc(db.doc(`clients/${clientId}`), clientQueryOptions);

  const getQueryRef = React.useCallback(() => {
    if (!client) return;
    if (!client.id) return;
    return db.collection('clients').doc(client.id);
  }, [client]);

  const [queryRef, setQueryRef] = React.useState(getQueryRef());

  React.useEffect(() => {
    if (!client) return;
    setSentryContext(client);
    if (!client.sessionReplay || client.sessionReplay.enabled !== false) {
      sessionReplay.init(client.id);
    }
    if (client.theme && client.theme.primaryColor) {
      setTheme({
        palette: {
          primary: {
            main: client.theme.primaryColor,
          },
        },
        shape: {
          borderRadius: 0,
        },
      });
    }
  }, [client, setTheme]);

  // Update Firebase query ref to collection('clients').doc(<clientId>)
  React.useEffect(() => {
    setQueryRef(getQueryRef());
  }, [getQueryRef]);

  // Allow remote page reload
  React.useEffect(() => {
    if (!client) return;
    if (reloadTimeout) clearTimeout(reloadTimeout);
    if (!queryRef) return;
    if (!process.env.REACT_APP_VERSION) return;
    const parseVersionNumber = (semver) => {
      return parseInt(semver.split('.').join(''));
    };
    const version = parseVersionNumber(process.env.REACT_APP_VERSION);
    if (!version) return;
    if (!client.minAppVersion) return;
    const minAppVersion = parseVersionNumber(client.minAppVersion);
    if (!minAppVersion) return;
    if (version >= minAppVersion) return;

    // Using timeout because we want to wait until client.minAppVersion is fetched (and not use it from the local db cache)
    reloadTimeout = setTimeout(() => {
      reloadTimeout = setTimeout(() => {
        window.location.reload();
      }, 1000);
    }, 5000);
  }, [queryRef, client]);

  const isFieldRequired = React.useCallback(
    (fieldName) => {
      if (!client) return false;
      if (!client.requiredFields) return false;
      return client.requiredFields.includes(fieldName);
    },
    [client]
  );

  const getFieldValidation = React.useCallback(
    (fieldName) => {
      if (!client) return false;
      if (!client.fieldValidations) return false;
      return client.fieldValidations[fieldName];
    },
    [client]
  );

  const getFieldLabel = React.useCallback(
    (fieldName, defaultLabel) => {
      return client.fieldLabels?.[fieldName]?.[i18n.language] || defaultLabel;
    },
    [client, i18n.language]
  );

  React.useEffect(() => {
    if (isLoading) return;
    if (!client || clientError) {
      captureException(new Error('Cannot log in'), {
        extra: { clientError, clientId, ...(!client && { client: 'No client' }) },
      });
    }
  }, [client, clientError, isLoading, clientId]);

  if (isLoading) return <CustomCircularProgress />;

  if (!client || clientError) {
    return (
      <CustomDialog open onClose={logout} primaryButtonText="Cerrar" onPrimaryClick={logout}>
        <Typography variant="h6">Para dar de alta de tu cuenta contacta nuestro soporte.</Typography>
      </CustomDialog>
    );
  }

  return (
    <ClientContext.Provider value={{ client, queryRef, isFieldRequired, getFieldValidation, getFieldLabel }}>
      {props.children}
      {!!shadowLogin.isShadowLogin && <ShadowLoginBar />}
      <DeviceControl />
    </ClientContext.Provider>
  );
};

export const ClientContext = React.createContext();

const clientQueryOptions = {
  idField: 'id',
  transform: getClient,
};
