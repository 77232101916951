import { captureException } from '@sentry/react';
import { fn } from '../firebase';

export const sendEmail = async (email) => {
  const sendEmail = fn.httpsCallable('sendEmail');
  try {
    await sendEmail(email);
  } catch (e) {
    captureException(e);
  }
};
