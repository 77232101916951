import { DEFAULT_CLIENT } from 'components/Client/clientConsts';
import { removeEmptyProps, isObjectEmpty } from 'utils/objectUtils';

export const getClient = (rawClient = {}) => {
  return [departmentWithLanguage, phoneCalls, removeEmptyProps].reduce(
    (client, fn) => {
      return fn(client);
    },
    { ...DEFAULT_CLIENT, ...rawClient }
  );
};

// Can be removed when all clients have departments as objects or do not have them at all
const departmentWithLanguage = (rawClient) => {
  let departments = rawClient.departments;
  if (Array.isArray(departments)) {
    departments = {
      es: departments,
      en: departments,
    };
  }
  return {
    ...rawClient,
    departments,
  };
};

// Removes `phoneCalls` if it does not have correct format
const phoneCalls = (rawClient) => {
  const { text = {} } = (rawClient.phoneCalls && rawClient.phoneCalls.onEnter) || {};
  if (isObjectEmpty(text)) {
    const { phoneCalls, ...client } = rawClient;
    return client;
  }
  return rawClient;
};
