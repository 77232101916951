import React from 'react';
import { Box, Container } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { useTheme } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { navigate, useLocation } from '@reach/router';
import { isMobileOnly } from 'react-device-detect';
import { Controller, useForm } from 'react-hook-form';
import { KeyboardProvider } from 'components/CustomKeyboard/KeyboardContext';
import { useKeyboard } from 'components/CustomKeyboard/keyboardHooks';
import { AppVersion } from 'components/Device/AppVersion';
import { deviceLog, getUserToken, LOG_EVENT_TYPES } from 'utils/deviceUtils';
import { useAuth } from '../Auth/authHooks';
import CustomTextField from '../CustomTextField/CustomTextField';

const LoginInner = () => {
  const form = useForm({
    defaultValues: {
      email: process.env.REACT_APP_DEFAULT_CLIENT_EMAIL || '',
      password: process.env.REACT_APP_DEFAULT_CLIENT_PASSWORD || '',
    },
  });
  const classes = useStyles();
  const centeredContent = React.useRef();
  const { state } = useLocation();
  const { user, login, loginWithToken } = useAuth();
  const { redirect = `${process.env.PUBLIC_URL}/visit` } = state || {};
  const theme = useTheme();
  const { height } = useKeyboard();

  React.useEffect(() => {
    deviceLog(LOG_EVENT_TYPES.render, { component: 'Login' });
  }, []);

  React.useEffect(() => {
    if (!user) {
      return;
    }
    // Redirect after successful login
    navigate(redirect);
  }, [user, loginWithToken, redirect]);

  const handleClick = async ({ email, password }) => {
    const { error } = await login(email, password);
    if (error) {
      form.setError('password', { message: 'El email o la contraseña no son correctos' });
    }
  };

  return (
    <>
      <AppVersion />
      <Container maxWidth="sm" style={{ paddingBottom: height, height: '100vh' }}>
        <Box display="flex" alignItems="center" height={1} width={1}>
          <Card
            style={{ padding: theme.spacing() * 2, width: '100%', position: 'relative', overflow: 'visible' }}
            ref={centeredContent}
          >
            <div
              style={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 10,
              }}
            >
              <Typography color="textSecondary" variant="h4">
                Iniciar Sesion
              </Typography>
            </div>
            <div style={{ display: 'flex', flexGrow: 1, flexDirection: 'column' }}>
              <div>
                <Controller
                  as={CustomTextField}
                  control={form.control}
                  label="Email"
                  name="email"
                  required
                  rules={{ required: 'El correo electrónico es obligatorio' }}
                  error={!!form.errors.email}
                  inputProps={{
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                />
              </div>
              <div>
                <Controller
                  as={CustomTextField}
                  control={form.control}
                  label="Contraseña"
                  name="password"
                  type="password"
                  required
                  rules={{ required: 'La contraseña es obligatoria' }}
                  error={!!form.errors.password}
                  inputProps={{
                    autoComplete: 'new-password',
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                />
              </div>
              <div className={classes.buttonDiv}>
                <Button
                  onClick={form.handleSubmit(handleClick)}
                  className={classes.button}
                  variant="contained"
                  fullWidth
                  disableElevation
                >
                  Entrar
                </Button>
              </div>
            </div>
            <Box position="absolute" top={centeredContent.current && centeredContent.current.clientHeight}>
              {Object.keys(form.errors).map((error) => (
                <Typography color="error" key={error}>
                  * {form.errors[error].message}
                </Typography>
              ))}
            </Box>
          </Card>
        </Box>
      </Container>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  buttonDiv: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
  },
  inputColor: {
    color: 'white',
    fontSize: 22,
  },

  button: {
    border: 0,
    borderRadius: 0,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    height: 70,
    fontSize: 22,
    display: 'flex',
    backgroundColor: '#ff4346',
    color: 'white',
  },
  errorr: {
    color: 'red',
    marginTop: -18,
    marginLeft: 'auto',
  },
  loadingIndicator: {
    left: 0,
    minHeight: 6,
    position: 'absolute',
    top: 0,
    width: '100%',
  },

  keyboardContainer: {
    display: isMobileOnly ? 'none' : 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
  },
  mobileText: {
    margin: theme.spacing(2),
    textAlign: 'center',
  },
}));

export const Login = (props) => (
  <KeyboardProvider>
    <LoginInner {...props} />
  </KeyboardProvider>
);
