import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, InputBase } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';

export const CustomSelect = React.forwardRef((props, ref) => {
  const classes = useStyles();
  const classesSelect = useSelectStyles();
  const classesLabel = useLabelStyles({ isError: props.error });
  const handleChange = (event) => {
    props.onChange(event.target.value);
  };

  return (
    <FormControl variant="filled" classes={classes}>
      <InputLabel id={`select-label-${props.name}`} classes={classesLabel} required={props.required}>
        {props.label}
      </InputLabel>
      <Select
        labelId={`select-label-${props.name}`}
        id={`select-${props.name}`}
        value={props.value}
        onChange={handleChange}
        classes={classesSelect}
        input={<SelectInput variant="filled" />}
        ref={ref}
      >
        {props.options &&
          props.options.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.title}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
});

const SelectInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
  },
  input: {
    fontSize: 20,
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  root: ({ isError }) => ({
    backgroundColor: '#D8D8D8',
    width: '100%',
  }),
}));

const useSelectStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  select: {
    '&:focus': {
      backgroundColor: 'unset',
    },
  },
  icon: {
    top: -theme.spacing(0.5),
  },
}));

const useLabelStyles = makeStyles((theme) => ({
  root: ({ isError }) => ({
    color: isError ? theme.palette.error.main : 'inherit',
    '&.Mui-focused': {
      color: theme.palette.text.disabled,
    },
  }),
}));
