import React from 'react';
import { Box } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';

export const CustomCircularProgress = (props) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
      <CircularProgress color="primary" size="3rem" thickness={3.5} />
    </Box>
  );
};
