import React from 'react';
import { Box } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import CameraIcon from '@material-ui/icons/PhotoCamera';
import Webcam from 'react-webcam';
import { retry } from 'utils/async';

export const CustomWebcam = React.forwardRef((props, ref) => {
  const theme = useTheme();
  const { onChange } = props;

  const capture = React.useCallback(async () => {
    // When a device does not have permissions to take capture a photo it returns 1x1 grey png.
    let imageSrc = DUMMY_IMAGE;
    try {
      const { result } = await retry(
        () => {
          if (!ref.current) throw new Error('Camera component not rendered');
          const photo = ref.current.getScreenshot();
          if (!photo) throw new Error('Camera not initialized yet');
          return photo;
        },
        50,
        { delay: 100 }
      );
      imageSrc = result;
    } catch (error) {
      console.error(error.message);
    }
    onChange(imageSrc);
  }, [onChange, ref]);

  React.useEffect(() => {
    if (!ref || !ref.current) return;
    ref.current.capture = capture;
  }, [capture, ref]);

  return (
    <Box
      width={props.width}
      height={props.width / 1.33}
      display="flex"
      justifyContent="center"
      alignItems="center"
      style={{ backgroundColor: theme.palette.input.main }}
    >
      {props.isActive ? (
        <Webcam
          height={props.width / 1.33}
          audio={false}
          ref={ref}
          screenshotFormat="image/jpeg"
          forceScreenshotSourceSize="true"
        />
      ) : props.image ? (
        <img src={props.image} alt="Foto de usted" style={{ height: '100%' }} />
      ) : (
        <CameraIcon fontSize="large" style={{ color: theme.palette.input.contrastText }} />
      )}
    </Box>
  );
});

const DUMMY_IMAGE =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mPc/h8AAnMBuLJ5qfEAAAAASUVORK5CYII=';
