import React, { useState } from 'react';
import {
  Box,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useClient } from 'components/Client/clientHooks';
import { CustomSelect } from 'components/CustomSelect/CustomSelect';
import CustomTextField from 'components/CustomTextField/CustomTextField';
import { CustomWebcam } from 'components/CustomWebcam/CustomWebcam';
import {
  removeSpaces,
  transcriptDigits,
  transcriptEmail,
  onlyDigits,
} from 'components/SpeechRecognition/speechRecognitionUtils';
import { useValidationSchema, useVisitForm } from 'components/Visit/visitHooks';
import { PHASES } from 'components/Visit/VisitProvider';
import { retry } from 'utils/async';
import { removeSpecialChars } from 'utils/stringUtils';
import { Phase } from './Phase';
import { db, useRealtimeQuery } from '../../firebase';
import Autocomplete from '@material-ui/lab/Autocomplete';

const VALIDATED_FIELDS = [
  'name',
  'surname',
  'email',
  'photo',
  'reason',
  'department',
  'employeeEmail',
  'employeeName',
  'employeePhone',
  'company',
];

export const PersonalDetails = (props) => {
  const { next } = props;
  const { visitForm, errorTypes } = useVisitForm();

  const { filter } = useValidationSchema();
  const webcamRef = React.useRef(null);
  const { client, isFieldRequired, getFieldLabel } = useClient();

  const [employees] = useRealtimeQuery(
    db.collection('clients').doc(client.id).collection('employees'),
    employeesOptions
  );

  const [open, setOpen] = useState(false);
  const [text, setText] = useState('');

  const isError = (fieldName) => !!visitForm.errors[fieldName];
  const cameraButtonClasses = useCameraButtonStyles({ isError: isError('photo') });
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const validate = () => {
    const values = visitForm.getValues();
    const validationSchema = filter(VALIDATED_FIELDS);
    return validationSchema.isValidSync(values);
  };
  const [isValid, setIsValid] = React.useState(validate());

  const onChange = (fieldOnChange) => (value) => {
    fieldOnChange(value);
    setIsValid(validate());
  };

  const handleSubmit = () => {
    const values = visitForm.getValues();
    const validationSchema = filter(VALIDATED_FIELDS);
    const isValid = validationSchema.isValidSync(values);
    if (isValid) {
      next();
      if (!values.email) {
        visitForm.setValue('receiveRulesEmail', false);
      }
      visitForm.trigger(VALIDATED_FIELDS); // trigger validation after leaving the phase to reset the eventual errors which were already solved
    } else {
      visitForm.trigger(VALIDATED_FIELDS);
    }
  };

  const getErrorNumber = (fieldName) => {
    const fieldError = visitForm.errors[fieldName];
    if (!fieldError) return null;
    const uniqueErrors = errorTypes.filter((error) => {
      return !!Object.values(visitForm.errors).find((formError) => error.types.includes(formError.type));
    });
    const errorIndex = uniqueErrors.findIndex((error) => {
      return error.types.includes(fieldError.type);
    }, 1);
    return errorIndex + 1;
  };

  return (
    <Phase phase={PHASES.personalDetails} next={handleSubmit} back={props.back} maxHeight={400} canNext={isValid}>
      <Grid container item sm spacing={1} justify="center">
        <Grid container item xs={12} sm c="Photo" spacing={1} style={{ zIndex: 2, flexGrow: 0 }}>
          <WebCamMemo
            visitForm={visitForm}
            onChange={onChange}
            webcamRef={webcamRef}
            cameraButtonClasses={cameraButtonClasses}
            isFieldRequired={isFieldRequired}
            isError={isError}
          />
        </Grid>
        <Grid container item xs={12} sm spacing={1} style={{ zIndex: 1 }}>
          <Grid item xs={12} style={{ display: 'flex', flexDirection: 'row' }}>
            <Box style={{ flex: 2.5, paddingRight: 3 }}>
              <Controller
                name="name"
                control={visitForm.control}
                render={(inputProps) => (
                  <CustomTextField
                    {...inputProps}
                    autoFocus
                    voiceToText
                    id="nombre"
                    label={t('name')}
                    required={isFieldRequired('name')}
                    error={isError('name')}
                    errorNumber={getErrorNumber('name')}
                    onChange={onChange(inputProps.onChange)}
                    setOpen={setOpen}
                    open={open}
                  />
                )}
              />
            </Box>
            <Box style={{ flex: 3, paddingLeft: 3 }}>
              <Controller
                name="surname"
                control={visitForm.control}
                render={(inputProps) => (
                  <CustomTextField
                    {...inputProps}
                    voiceToText
                    id="apellidos"
                    label={t('surname')}
                    required={isFieldRequired('surname')}
                    error={isError('surname')}
                    errorNumber={getErrorNumber('surname')}
                    onChange={onChange(inputProps.onChange)}
                    setOpen={setOpen}
                    open={open}
                  />
                )}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="company"
              control={visitForm.control}
              render={(inputProps) => (
                <CustomTextField
                  {...inputProps}
                  voiceToText
                  id="company"
                  label={getFieldLabel('company', t('company'))}
                  required={isFieldRequired('company')}
                  error={isError('company')}
                  errorNumber={getErrorNumber('company')}
                  onChange={onChange(inputProps.onChange)}
                  setOpen={setOpen}
                  open={open}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="email"
              control={visitForm.control}
              render={(inputProps) => (
                <CustomTextField
                  {...inputProps}
                  id="email"
                  label={t('email')}
                  voiceToText={{ onTranscript: [transcriptEmail] }}
                  transformations={[removeSpaces, removeSpecialChars]}
                  error={isError('email')}
                  errorNumber={getErrorNumber('email')}
                  required={isFieldRequired('email')}
                  onChange={onChange(inputProps.onChange)}
                  setOpen={setOpen}
                  open={open}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography display="inline" style={{ textTransform: 'uppercase' }}>
            <Box fontWeight="fontWeightLight" component="span" color="grey.500">
              {t('reason')}
            </Box>
          </Typography>
        </Grid>
        {!client.showOnlyCommentField && (
          <>
            <Grid item xs={12} sm={7}>
              {employees.length > 0 && (
                <Autocomplete
                  options={employees
                    .filter((item) => {
                      const name = item.name.toUpperCase();
                      if (name.includes(text.toUpperCase())) {
                        return item;
                      } else {
                        return false;
                      }
                    })
                    .sort((a, b) => (a.name !== b.name ? (a.name < b.name ? -1 : 1) : 0))}
                  open={open}
                  onClose={() => setOpen(false)}
                  openOnFocus={false}
                  getOptionLabel={(option) => option.name || ''}
                  style={{ backgroundColor: 'black', color: 'white', height: 0 }}
                  clearOnBlur={false}
                  onChange={(e, data) => {
                    visitForm.setValue('employeeName', data ? data.name : '');
                    visitForm.setValue('employeeEmail', data ? data.email : '');
                    visitForm.setValue('employeePhone', data ? data.phoneNumber : '');
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="dense"
                      autoComplete="off"
                      value={visitForm.control.getValues().employeeName}
                      type={'text'}
                      fullWidth
                      spellCheck="false"
                      variant="filled"
                      style={{ height: 0 }}
                    />
                  )}
                />
              )}
              <Controller
                name="employeeName"
                control={visitForm.control}
                render={(inputProps) => (
                  <CustomTextField
                    {...inputProps}
                    voiceToText
                    id="employeeName"
                    label={t('employeeName')}
                    error={isError('employeeName')}
                    errorNumber={getErrorNumber('employeeName')}
                    required={isFieldRequired('employeeName')}
                    onChange={(value) => {
                      setText(value);
                      inputProps.onChange(value);
                      setIsValid(validate());
                    }}
                    setOpen={setOpen}
                    open={open}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={5} style={{ order: isXs ? 1 : 'initial' }}>
              <Controller
                name="reason"
                control={visitForm.control}
                render={(inputProps) => (
                  <CustomSelect
                    {...inputProps}
                    options={
                      client.reasons[i18n.language] &&
                      client.reasons[i18n.language].map((item) => ({ value: item, title: item }))
                    }
                    id="reason"
                    label={t('reason')}
                    required={isFieldRequired('reason')}
                    error={isError('reason')}
                    onChange={onChange(inputProps.onChange)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={7}>
              <Controller
                name="employeeEmail"
                control={visitForm.control}
                render={(inputProps) => (
                  <CustomTextField
                    {...inputProps}
                    onChange={(value) => {
                      // Append email domain when employeeEmailDomain
                      const appended =
                        client.employeeEmailDomain && value ? [value, client.employeeEmailDomain].join('@') : value;
                      onChange(inputProps.onChange)(appended);
                    }}
                    value={
                      client.employeeEmailDomain
                        ? inputProps.value.replace(`@${client.employeeEmailDomain}`, '') // Strip email domain
                        : inputProps.value
                    }
                    label="Email de la persona visitada"
                    voiceToText={{ onTranscript: [transcriptEmail] }}
                    transformations={[removeSpaces, removeSpecialChars]}
                    id="employeeEmail"
                    error={isError('employeeEmail')}
                    errorNumber={getErrorNumber('employeeEmail')}
                    required={isFieldRequired('employeeEmail')}
                    setOpen={setOpen}
                    open={open}
                    {...(client.employeeEmailDomain && {
                      InputProps: {
                        endAdornment: () => (
                          <InputAdornment position="end">
                            <Typography style={{ fontSize: 17, marginTop: theme.spacing(2) }}>
                              @{client.employeeEmailDomain}
                            </Typography>
                          </InputAdornment>
                        ),
                      },
                    })}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={5} style={{ order: isXs ? 1 : 'initial' }}>
              <Controller
                name="department"
                control={visitForm.control}
                render={(inputProps) => (
                  <CustomSelect
                    {...inputProps}
                    options={
                      client.departments[i18n.language] &&
                      client.departments[i18n.language].map((item) => ({ value: item, title: item }))
                    }
                    id="department"
                    label={t('department')}
                    required={isFieldRequired('department')}
                    error={isError('department')}
                    onChange={onChange(inputProps.onChange)}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={7}>
              <Controller
                name="employeePhone"
                control={visitForm.control}
                render={(inputProps) => (
                  <CustomTextField
                    {...inputProps}
                    onChange={(value) => {
                      if (!value) {
                        inputProps.onChange(value);
                        onChange(inputProps.onChange)(value);
                        return;
                      }
                      // Prepend phone prefix when employeePhonePrefix
                      const prepended = parseInt(
                        client.employeePhonePrefix ? [client.employeePhonePrefix, value].join('') : value
                      );
                      onChange(inputProps.onChange)(prepended);
                    }}
                    value={
                      client.employeePhonePrefix
                        ? inputProps.value.toString().replace(client.employeePhonePrefix, '') // Strip phone prefix
                        : inputProps.value
                    }
                    voiceToText={{ onTranscript: [transcriptDigits] }}
                    transformations={[removeSpaces, onlyDigits]}
                    id="employeePhone"
                    label={t('employeePhone')}
                    error={isError('employeePhone')}
                    errorNumber={getErrorNumber('employeePhone')}
                    required={isFieldRequired('employeePhone')}
                    setOpen={setOpen}
                    open={open}
                    inputProps={{
                      type: 'tel',
                    }}
                    {...(client.employeePhonePrefix && {
                      InputProps: {
                        startAdornment: () => (
                          <InputAdornment position="start">
                            <Typography style={{ fontSize: 17, marginTop: 2 }}>{client.employeePhonePrefix}</Typography>
                          </InputAdornment>
                        ),
                      },
                    })}
                  />
                )}
              />
            </Grid>
          </>
        )}
        <Grid item xs={12} sm={client.showOnlyCommentField ? 12 : 5} style={{ order: isXs ? 2 : 'initial' }}>
          <Controller
            name="comment"
            control={visitForm.control}
            render={(inputProps) => (
              <CustomTextField
                {...inputProps}
                id="comment"
                label={t('visitComment')}
                voiceToText
                onChange={onChange(inputProps.onChange)}
                setOpen={setOpen}
                open={open}
                {...(client.showOnlyCommentField && { rows: 5, multiline: true })}
              />
            )}
          />
        </Grid>
      </Grid>
    </Phase>
  );
};

const employeesOptions = {
  idField: 'id',
};

const useCameraButtonStyles = makeStyles((theme) => ({
  label: ({ isError }) => ({
    color: isError ? theme.palette.error.main : 'inherit',
  }),
}));

const WebCamMemo = React.memo(
  ({ visitForm, onChange, webcamRef, cameraButtonClasses, isFieldRequired, isError }) => {
    return (
      <Controller
        name="photo"
        control={visitForm.control}
        as={React.forwardRef((inputProps, ref) => {
          return (
            <>
              <Grid item xs style={{ display: 'flex', justifyContent: 'center' }}>
                <Box width={175}>
                  <CustomWebcam
                    {...inputProps}
                    width={175}
                    isActive={!inputProps.value}
                    image={inputProps.value}
                    onChange={onChange(inputProps.onChange)}
                    ref={webcamRef}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  disableElevation
                  onClick={async () => {
                    if (inputProps.value) {
                      onChange(inputProps.onChange)('');
                      await retry(
                        () => {
                          if (!webcamRef.current) throw new Error();
                          return true;
                        },
                        3,
                        { delay: 1500 }
                      );
                      window.document.body.click(); // Without this the camera is active but black on tablet
                    } else {
                      await webcamRef?.current?.capture();
                    }
                  }}
                  variant="contained"
                  fullWidth
                  classes={cameraButtonClasses}
                  color={isFieldRequired('photo') && !inputProps.value ? 'primary' : 'default'}
                >
                  {inputProps.value ? 'Activar' : 'Capturar'}
                  {isError('photo') ? '\u2009*' : ''}
                </Button>
              </Grid>
            </>
          );
        })}
      />
    );
  },
  () => {
    return true;
  }
);
