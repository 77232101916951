import React from 'react';
import moment from 'moment';
import { FORMATS } from 'utils/dateUtils';

window.viloClockOnterval = null;

export const Clock = (props) => {
  const [time, setTime] = React.useState(moment().format(props.format || FORMATS.full));

  React.useEffect(() => {
    if (!window.viloClockOnterval) {
      window.viloClockOnterval = setInterval(() => {
        const formatted = moment().format(props.format || FORMATS.full);
        if (window.viloClockOnterval) {
          setTime(formatted);
        }
      }, 1000);
    }
  }, [props.format]);

  React.useEffect(() => {
    return () => {
      clearInterval(window.viloClockOnterval);
      window.viloClockOnterval = null;
    };
  }, []);

  return time;
};
