import { withStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';

export const MainButton = withStyles((theme) => ({
  root: {
    height: theme.spacing(8),
  },
  label: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightBold,
  },
}))(Button);
