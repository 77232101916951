import React from 'react';
import { createMuiTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import merge from 'lodash/merge';

export const DEFAULT_THEME = {
  palette: {
    primary: {
      main: '#ff4346',
    },
    secondary: {
      main: '#5176BE',
    },
    background: {
      default: '#eeeeee',
    },
    input: {
      main: '#000',
      hint: '#a3a3a3',
      contrastText: '#fff',
    },
  },
  shape: {
    borderRadius: 0,
  },
  typography: {
    useNextVariants: true,
    fontFamily: 'Montserrat',
    fontWeightRegular: 600,
    fontWeightLight: 400,
  },
};

export const ThemeContext = React.createContext();

export const ThemeProvider = (props) => {
  const [theme, setThemeInternal] = React.useState(createMuiTheme(DEFAULT_THEME));

  const setTheme = React.useCallback((theme) => {
    setThemeInternal(createMuiTheme(merge(DEFAULT_THEME, theme)));
  }, []);

  return (
    <ThemeContext.Provider value={{ setTheme }}>
      <MuiThemeProvider theme={theme}>{props.children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};
