import React from 'react';
import { Typography } from '@material-ui/core';
import { captureException } from '@sentry/react';
import moment from 'moment';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocalStorage } from 'react-use';
import { CustomDialog } from 'components/CustomDialog/CustomDialog';

export default ({ children }) => {
  const [lastReload, setLastReload] = useLocalStorage('lastForcedReload');

  const onError = (error) => {
    captureException(error);
    if (process.env.NODE_ENV === 'development') return;
    if (!window.Android) return; // Reload only Kiosk devices
    if (lastReload) {
      const reloadedBefore = moment().diff(moment(lastReload), 'seconds');
      if (reloadedBefore < 10) {
        return; // Reloaded before 10 seconds. Don't reload again.
      }
    }
    // Timout to refresh the page to get time to the Sentry request to finish
    setTimeout(() => {
      setLastReload(new Date().getTime());
      window.location.reload();
    }, 1000);
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={onError}>
      {children}
    </ErrorBoundary>
  );
};

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <CustomDialog onPrimaryClick={() => window.location.reload()} primaryButtonText="Reiniciar" open>
      <Typography variant="h5">Algo ha salido mal</Typography>
    </CustomDialog>
  );
};
