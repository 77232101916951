import React from 'react';
import { Box, Checkbox, Grid, Hidden, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useClient } from 'components/Client/clientHooks';
import { useKeyboard } from 'components/CustomKeyboard/keyboardHooks';
import { PHASES } from 'components/Visit/VisitProvider';
import { Phase } from './Phase';
import { useVisitForm } from './visitHooks';

export const Questionary = (props) => {
  const { client } = useClient();
  const { t, i18n } = useTranslation();
  const { visitForm } = useVisitForm();
  const classes = useStyles();
  const { next } = props;
  const keyboard = useKeyboard();
  const [checked, setChecked] = React.useState(client.questionary.map((q) => false));
  const [dataProcess, setDataProcess] = React.useState(client.questionaryUserData.map((q) => false));
  React.useEffect(() => {
    if (client.questionary.length === 0) {
      next(); // When there is no questionary
    }
  }, [client.questionary, next]);

  const validate = React.useCallback(() => {
    return checked.every(Boolean);
  }, [checked]);

  const handleSubmit = () => {
    if (validate()) {
      visitForm.setValue('dataProcessing', dataProcess);
      props.next();
    }
  };

  return (
    <Phase phase={PHASES.questionary} next={handleSubmit} back={props.back} canNext={validate()}>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        item
        xs
        style={{
          overflow: 'auto',
          height: `calc(100vh - ${keyboard.height || 0}px - 70px)`,
        }}
      >
        {!client.questionaryDisabled && (
          <Grid container item xs justify="flex-end" alignItems="flex-start">
            <Grid container direction="column" item>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  paddingLeft={1}
                  color="secondary.contrastText"
                  style={{ backgroundColor: '#4b4b4b' }}
                  height={80}
                >
                  <Typography display="inline" style={{ textTransform: 'uppercase' }}>
                    {t('questionaryTitle')}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} container justify="flex-end">
                {client.questionary.map((question, i) => (
                  <React.Fragment key={i}>
                    <Grid
                      item
                      xs={12}
                      sm={11}
                      style={{
                        ...(i !== 0 && { paddingTop: 0 }), // Omit padding of first item
                        ...(i !== client.questionary.length - 1 && { paddingBottom: 0 }), // Omit padding of last item
                      }}
                    >
                      <Box className={classes.background} height={1} width={1} padding={1}>
                        <Typography>{question[i18n.language] || question.es || question.en}</Typography>
                      </Box>
                    </Grid>
                    <Hidden xsUp>
                      <Grid item xs sm></Grid>
                    </Hidden>
                    <Grid item xs="auto" sm={1}>
                      <Box height={1} width={1}>
                        <Checkbox
                          onChange={(e) => {
                            setChecked(
                              checked.map((state, index) => {
                                if (index === i) {
                                  return e.target.checked;
                                }
                                return state;
                              })
                            );
                          }}
                          color="primary"
                        />
                      </Box>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
        {client.id === 'maflow' && !client.showDataQuestionary && (
          <Grid container item xs justify="flex-end" alignItems="flex-start">
            <Grid container direction="column" item>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  paddingLeft={1}
                  color="secondary.contrastText"
                  style={{ backgroundColor: '#4b4b4b' }}
                  height={80}
                >
                  <Typography display="inline" style={{ textTransform: 'uppercase' }}>
                    {t('questionaryTitleUserData')}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} container justify="flex-end">
                {client.questionaryUserData.map((question, i) => (
                  <React.Fragment key={i}>
                    <Grid
                      item
                      xs={12}
                      sm={11}
                      style={{
                        ...(i !== 0 && { paddingTop: 0 }), // Omit padding of first item
                        ...(i !== client.questionary.length - 1 && { paddingBottom: 0 }), // Omit padding of last item
                      }}
                    >
                      <Box className={classes.background} height={1} width={1} padding={1}>
                        <Typography>{question[i18n.language] || question.es || question.en}</Typography>
                      </Box>
                    </Grid>
                    <Hidden xsUp>
                      <Grid item xs sm></Grid>
                    </Hidden>
                    <Grid item xs="auto" sm={1}>
                      <Box height={1} width={1}>
                        <Checkbox
                          onChange={(e) => {
                            setDataProcess(
                              dataProcess.map((state, index) => {
                                if (index === i) {
                                  return e.target.checked;
                                }
                                return state;
                              })
                            );
                          }}
                          color="primary"
                        />
                      </Box>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Phase>
  );
};

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: 'lightgrey',
  },
  activeButton: {
    backgroundColor: 'black',
    color: 'white',
  },
}));
