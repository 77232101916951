import random from 'lodash/random';

export const retry = async (fn, maxAttempts, options = {}, attempt = 1) => {
  try {
    void options.onBeforeRetry?.(attempt);
    const result = await fn();
    return { attempt, result };
  } catch (error) {
    if (attempt === maxAttempts) {
      throw error;
    }
    if (
      options.doNotRetryErrors &&
      options.doNotRetryErrors.find((doNotRetryError) => error instanceof doNotRetryError)
    ) {
      throw error;
    }
    await new Promise((resolve) => setTimeout(resolve, options.delay || random(1000, 1500)));
    return retry(fn, maxAttempts, options, attempt + 1);
  }
};
