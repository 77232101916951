import React from 'react';
import { Box, CircularProgress, Grid, Typography, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useClient } from 'components/Client/clientHooks';
import { useCreateVisit, usePhoneCall, usePrintVisit, useSendEmails, useVisitForm } from 'components/Visit/visitHooks';
import { PHASES } from 'components/Visit/VisitProvider';
import { deviceLog, LOG_EVENT_TYPES } from 'utils/deviceUtils';
import { Phase } from './Phase';

export const Result = (props) => {
  const { client } = useClient();
  const { visitForm, defaultValues } = useVisitForm();
  const { t } = useTranslation();
  const { next } = props;
  const theme = useTheme();
  const [state, setState] = React.useState('idle');
  const createVisit = useCreateVisit();
  const sendEmails = useSendEmails();
  const makeAPhoneCall = usePhoneCall();
  const printVisit = usePrintVisit();

  const onResult = React.useCallback(() => {
    setTimeout(
      () => {
        visitForm.reset(defaultValues);
        deviceLog(LOG_EVENT_TYPES.saveVisit, 'saved');
        next();
      },
      window.Cypress ? 50 : client.printVisit.enabled ? 5000 : 3000
    );
  }, [next, visitForm, defaultValues, client.printVisit.enabled]);

  React.useEffect(() => {
    if (state !== 'idle') return;
    setState('loading');
    (async () => {
      const { data } = await createVisit(visitForm.getValues());
      if (data) {
        setState('success');
        sendEmails(data, props.rulesPdf.downloadUrl);
        makeAPhoneCall(data);
        printVisit(data);
      } else {
        setState('error');
      }
      onResult();
    })();
  }, [
    createVisit,
    sendEmails,
    makeAPhoneCall,
    printVisit,
    visitForm,
    state,
    onResult,
    props.rulesPdf.downloadUrl,
    client,
  ]);

  return (
    <Phase phase={PHASES.result} fullHeight>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width={1}
        style={{ backgroundColor: '#4b4b4b' }}
        color="secondary.contrastText"
      >
        {state === 'loading' && (
          <Box
            marginBottom={2}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height={1}
          >
            <Box marginBottom={2}>
              <Typography color="inherit" variant="h4" align="center">
                {t('saving')}
              </Typography>
            </Box>
            <CircularProgress color="primary" size="3rem" thickness={3.5} />
          </Box>
        )}
        {state === 'success' && (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Box width={1}>
              <Typography color="inherit" variant="h4" align="center">
                {t('visitConfirmation')}
              </Typography>
            </Box>
            {client.printVisit.enabled && (
              <Grid
                container
                style={{
                  width: '70%',
                  marginTop: theme.spacing(8),
                  textAlign: 'center',
                }}
              >
                <Grid item xs={12} sm={6}>
                  <Box display="inline-flex" flexDirection="column" alignItems="center">
                    <Box marginTop={4}>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/printingTicket.svg`}
                        alt="printing ticket"
                        style={{ height: 200 }}
                      />
                    </Box>
                    <Typography color="inherit" variant="h6" align="center" style={{ width: 0, minWidth: '100%' }}>
                      {t('printingTicket')}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Box display="inline-flex" flexDirection="column" alignItems="center">
                    <Box marginTop={4}>
                      <img
                        src={`${process.env.PUBLIC_URL}/images/placeTicket.svg`}
                        alt="place ticket"
                        style={{ height: 200 }}
                      />
                    </Box>
                    <Typography color="inherit" variant="h6" align="center" style={{ width: 0, minWidth: '100%' }}>
                      {t('placeTicket')}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            )}
          </Box>
        )}
        {state === 'error' && (
          <Typography color="inherit" variant="h4" align="center">
            {t('visitConfirmationError')}
          </Typography>
        )}
      </Box>
    </Phase>
  );
};
