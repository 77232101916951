import React, { useState, useRef } from 'react';
import { Box } from '@material-ui/core';
import Keyboard from 'react-simple-keyboard';
import { useKeyboard } from 'components/CustomKeyboard/keyboardHooks';
import 'react-simple-keyboard/build/css/index.css';

export const CustomKeyboard = React.forwardRef((props, ref) => {
  const boxRef = useRef();
  const [layoutName, setLayoutName] = useState('default');
  const { setHeight } = useKeyboard();

  React.useEffect(() => {
    if (!boxRef || !boxRef.current) return;
    setHeight(boxRef.current.clientHeight);
  }, [boxRef, setHeight]);

  //Layout change(shift/caps)
  const handleShift = () => {
    const newLayoutName = layoutName === 'default' ? 'shift' : 'default';
    setLayoutName(newLayoutName);
  };

  const onKeyPress = (button) => {
    if (button === '{shift}' || button === '{lock}') handleShift();
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      marginTop={2}
      width={1}
      position="absolute"
      bottom={0}
      paddingX={1}
      paddingBottom={2}
      ref={boxRef}
      style={{ backgroundColor: '#2e2e2e' }}
    >
      <Keyboard
        keyboardRef={(r) => (ref.current = r)}
        preventMouseDownDefault={true}
        layoutName={layoutName}
        layout={props.layout || LAYOUTS.default}
        display={{
          '{bksp}': '⌫',
          '{space}': 'ESPACIO',
          '{shift}': '⇧',
        }}
        onKeyPress={onKeyPress}
        onChange={props.onChange}
        theme={'hg-theme-default myTheme1'}
      ></Keyboard>
    </Box>
  );
});

export const LAYOUTS = {
  dni: {
    default: [
      '1 2 3 4 5 6 7 8 9 0 {bksp}',
      'Q W E R T Y U I O P [ ] \\',
      "A S D F G H J K L Ñ ; '",
      '{shift} Z X C V B N M , . /',
      '.com @ {space}',
    ],
    shift: [
      '1 2 3 4 5 6 7 8 9 0 {bksp}',
      'Q W E R T Y U I O P [ ] \\',
      "A S D F G H J K L Ñ ; '",
      '{shift} Z X C V B N M , . /',
      '.com @ {space}',
    ],
  },
  default: {
    default: [
      '` 1 2 3 4 5 6 7 8 9 0 - = {bksp}',
      'q w e r t y u i o p [ ] \\',
      "a s d f g h j k l ñ ; '",
      '{shift} z x c v b n m , . /',
      '.com @ {space}',
    ],
    shift: [
      '~ ! @ # $ % ^ & * ( ) _ + {bksp}',
      'Q W E R T Y U I O P { } |',
      'A S D F G H J K L : "',
      '{shift} Z X C V B N M < > ?',
      '.com @ {space}',
    ],
  },
};
