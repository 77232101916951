import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { en } from 'i18n/en';
import { es } from 'i18n/es';

i18n.use(initReactI18next).init({
  resources: { es, en },
  lng: 'es',
  fallbackLng: 'es',

  interpolation: {
    escapeValue: false,
  },
});

export const I18n = (props) => {
  return props.children;
};
