import React from 'react';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { useAuth } from 'components/Auth/authHooks';
import { CustomKeyboard, LAYOUTS } from 'components/CustomKeyboard/CustomKeyboard';

export const KeyboardProvider = (props) => {
  const theme = useTheme();
  const { user, isExternal } = useAuth();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const [height, setHeight] = React.useState();
  const [layout, setLayout] = React.useState(LAYOUTS.default);
  const [onChange, setOnChangeInner] = React.useState(() => () => {});
  const ref = React.useRef();

  const setValue = React.useCallback(
    (value, isSw) => {
      if (!ref || !ref.current) return;
      ref.current.setInput(value);
      onChange(value, isSw);
    },
    [onChange]
  );

  const reset = React.useCallback(() => {
    if (!ref || !ref.current) return;
    ref.current.setInput('');
  }, []);

  const setOnChange = React.useCallback((onChange) => {
    setOnChangeInner(() => onChange || (() => {}));
  }, []);

  const isVisible = !isXs && (!user || !isExternal);

  return (
    <KeyboardContext.Provider
      value={{
        setValue,
        setHeight,
        setOnChange,
        setLayout,
        isVisible,
        height: isVisible ? height : 0,
        keyboardRef: ref,
        reset,
      }}
    >
      {isVisible ? height && props.children : props.children}
      {isVisible && <CustomKeyboard onChange={(value) => setValue(value, true)} layout={layout} ref={ref} />}
    </KeyboardContext.Provider>
  );
};

export const KeyboardContext = React.createContext();
