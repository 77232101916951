export const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
};

window.viloDebounce = null;
export const debounce = (delay, fn) => {
  clearTimeout(window.viloDebounce);
  window.viloDebounce = setTimeout(fn, delay);
};
