import produce from 'immer';

export const isObjectEmpty = (obj) => Object.keys(obj).length === 0;

export const removeEmptyProps = (obj) => {
  return produce(obj, (draft) => {
    Object.keys(obj).forEach((prop) => {
      if (obj[prop] === undefined || obj[prop] === '') {
        delete draft[prop];
      }
    });
  });
};

export const keepProps = (obj, props) => {
  return produce(obj, (draft) => {
    Object.keys(obj).forEach((prop) => {
      if (!props.includes(prop)) {
        delete draft[prop];
      }
    });
  });
};
