import React from 'react';
import { captureException } from '@sentry/react';
import { useCollection, useDocument } from 'react-firebase-hooks/firestore';
import { config, PROJECT_ID } from 'fb/config.js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage';
import { getCookie } from 'utils/browserUtils.js';

const env = process.env.REACT_APP_ENV || 'production';

const firebaseApp = firebase.initializeApp(config[env]);
const db = firebaseApp.firestore();
const au = firebaseApp.auth();
const storage = firebaseApp.storage();
const fn = firebaseApp.functions();

if (
  (process.env.NODE_ENV === 'development' &&
    (!process.env.REACT_APP_DISABLE_EMULATORS || process.env.REACT_APP_DISABLE_EMULATORS === 'false')) ||
  getCookie('useEmulators')
) {
  db.settings({
    experimentalForceLongPolling: true,
  });
  fn.useEmulator(window.location.hostname, 5001);
  db.useEmulator(window.location.hostname, 8089);
  au.useEmulator(`http://${window.location.hostname}:9099`);
}

db.enablePersistence().catch(function (err) {
  if (err.code === 'failed-precondition') {
    console.log("More tabs opened. Offline support won't work.");
  } else if (err.code === 'unimplemented') {
    console.log('Offline mode not supported.');
  }
});

export { db, au, fn, storage };

export const useRealtimeQuery = (query, options) => {
  const [data, setData] = React.useState([]);
  const [docs, setDocs] = React.useState([]);
  const [value, loading, error] = useCollection(query, options);

  React.useEffect(() => {
    if (!value) return;
    setData(
      value.docs.map((doc) => {
        const docData = {
          // why parameter serverTimestamps: https://github.com/firebase/firebase-js-sdk/issues/1929#issuecomment-506982593
          ...doc.data({ serverTimestamps: 'estimate' }),
          ...(options && options.idField && { [options.idField]: doc.id }),
        };
        if (options && options.transform) {
          return options.transform(docData);
        }
        return docData;
      })
    );
    setDocs(value.docs); // To be able to access raw documents (e.g. for pagination)
  }, [value, options]);

  return [data, loading, error, docs];
};

export const useRealtimeDoc = (query, options) => {
  const [data, setData] = React.useState();
  const [doc, loading, error] = useDocument(query, options);
  const [isLoading, setIsLoading] = React.useState(loading);

  React.useEffect(() => {
    if (!doc) return;
    if (!doc.exists) {
      setIsLoading(false);
      return;
    }
    let value = {
      ...doc.data({ serverTimestamps: 'estimate' }),
      ...(options && options.idField && { [options.idField]: doc.id }),
    };
    if (options && options.transform) {
      let transformers = options.transform;
      if (!Array.isArray(transformers)) {
        transformers = [options.transform];
      }
      value = transformers.reduce((result, transformer) => {
        return transformer(result);
      }, value);
    }
    setData(value);
    setIsLoading(false);
  }, [doc, options]);

  React.useEffect(() => {
    if (error) {
      setIsLoading(false);
    }
  }, [error]);

  return [data, isLoading, error];
};

export const createFunctionUrl = (...args) => {
  if (
    (process.env.NODE_ENV === 'development' || window.Cypress) &&
    (!process.env.REACT_APP_DISABLE_EMULATORS || process.env.REACT_APP_DISABLE_EMULATORS === 'false')
  ) {
    return `http://localhost:5001/${PROJECT_ID}/us-central1/${[...args].join('/')}`;
  }
  return `https://us-central1-${PROJECT_ID}.cloudfunctions.net/${[...args].join('/')}`;
};

export const useStorage = (path, options = {}) => {
  const [downloadUrl, setDownloadUrl] = React.useState();
  const [isLoading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState();
  const [raw, setRaw] = React.useState();

  React.useEffect(() => {
    if (options.enabled !== undefined && options.enabled === false) {
      setIsLoading(false);
      return;
    }
    (async () => {
      setIsLoading(true);
      try {
        const url = await storage.ref(path).getDownloadURL();
        if (options.raw) {
          const r = await new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = function () {
              resolve(xhr.response);
            };
            xhr.onerror = reject;
            xhr.open('GET', url);
            xhr.send();
          });
          setRaw(r);
        }
        setDownloadUrl(url);
      } catch (e) {
        if (window.Cypress) {
          // For testing purposes
          setRaw(path);
          setDownloadUrl(path);
          return;
        }
        setError(e);
        captureException(e);
      }
      setIsLoading(false);
    })();
  }, [path, options.raw, options.enabled]);

  return { isLoading, downloadUrl, error, raw };
};
