import { db } from 'firebase.js';
import moment from 'moment';
import { FORMATS } from 'utils/dateUtils.js';
import { createFunctionUrl } from '../../firebase';

/**
 * Parses `2001-12-31` to separate values
 * @param {array} accesses
 * @param {string} nif
 * @returns {Object}
 */
export const getAccessByNif = (accesses, nif) => {
  return accesses.find((access) => access.nif.toUpperCase() === nif.toUpperCase());
};

/**
 * @returns {Boolean}
 */
export const isInside = (accesses, nif) => {
  if (!nif) return false;
  const access = getAccessByNif(accesses, nif);
  return (access && !access.exit) || false;
};
export const isExitAuthorized = (accesses, nif) => {
  const access = getAccessByNif(accesses, nif);
  return access && !!access.exitAuthorizationTimestamp;
};

export const createEmailForEmployee = (client, to, visit) => {
  const authorizeExitLink = createFunctionUrl('authorizeExit', client.id, visit.id);
  return {
    to: to,
    subject: 'Ha recibido una visita',
    html: `Hola
    <br />
    Se acaba de registrar la visita de ${visit.name}${visit.company ? ` de la empresa ${visit.company}` : ''}.<br />
    Fecha y Hora de la visita: ${moment().format(FORMATS.full)}<br />
    <br />
    Datos del visitante:<br />
    Nombre: ${visit.name}<br />
    Empresa: ${visit.company || ''}<br />
    DNI: ${visit.nif || ''}<br />
    <br />
    Datos de la visita:<br />
    Motivo: ${visit.reason || ''}<br />
    Departamento: ${visit.department || ''}<br />
    Comentario: ${visit.comment || ''}<br />
    <br />
    ${
      client.exitAuthorizations.email
        ? `Una vez finalizada la visita, pulse el siguiente enlace para dar consentimiento de su finalización y que el visitante pueda efectuar su salida: <a href="${authorizeExitLink}">Autorizar salida.</a>`
        : ''
    }
    <br />
    <br />
    Un saludo,
    <br />
    <br />
    https://registrodevisitas.es`,
  };
};

export const createEmailForVisitor = (visit, clientName, pdfUrl) => {
  return {
    to: visit.email,
    subject: `Bienvenido a ${clientName}`,
    html: `Hola ${visit.name},<br />
    <br />
    Su visita a ${clientName} se ha registrado correctamente el ${moment().format(FORMATS.admin)}.<br />
    <br />
    ${
      pdfUrl
        ? `Le adjuntamos las instrucciones de seguridad.<br />Asegure su lectura y entendimiento.<br />Téngalas a mano durante la visita.<br />`
        : ''
    }
    <br />
    Un cordial saludo`,
    ...(pdfUrl && { attachments: [{ filename: 'Reglas de securidad.pdf', path: pdfUrl }] }),
  };
};
export const createEmailForVisitorExit = (visit, clientName) => {
  return {
    to: visit.email,
    subject: `Gracias por visitar a ${clientName}`,
    html: `<div><img src="cid:logoForEmail" alt="image" /></div>`,
    attachments: [
      {
        filename: 'image.png',
        path: `./image.png`,
        cid: 'logoForEmail',
      },
    ],
  };
};

export const createPhoneCall = (client, visit, language = 'es') => {
  if (!client.phoneCalls) return null;
  if (!client.phoneCalls.enabled) return null;
  if (!visit.employeePhone) return null;
  const message = client.phoneCalls.onEnter.text[language];
  if (!message) return null;
  const text = message.replace(/{name}/g, visit.name).replace(/{company}/g, visit.company);
  return {
    client: 'soincon', // All phone call are called with `soincon` client so the phone call provider will use soincon account
    to: [parseInt(visit.employeePhone)],
    text,
    language: 'es_ES',
    gender: 'M',
  };
};

export const createPrintTicketJob = (client, visitRaw) => {
  if (!client.printVisit.enabled) return null;
  const { photo, receiveRulesEmail, entry, ...visit } = visitRaw;
  return {
    printer: client.id === 'seg' ? 'seg-printer' : client.id,
    data: {
      ...visit,
      controller: visit.employeeName,
      date: moment().format('DD.MM.YYYY'),
      time: moment().format('HH:mm'),
    },
  };
};

export const closeVisits = (client, visits) => {
  return Promise.all(
    visits.map((visit) => {
      return db.doc(`clients/${client.id}/accesses/${visit.id}`).update({ exit: new Date() });
    })
  );
};
