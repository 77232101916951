import soinconLogo from 'components/Client/soinconLogo.js';
export const DEFAULT_REASONS = {
  es: [
    'Visita comercial',
    'Reunión de proyecto',
    'Entrega Materiales',
    'Reconocimiento médico',
    'Entrevista RRHH',
    'Visita técnica',
    'Personal',
  ],
  en: [
    'Comercial visit',
    'Project meeting',
    'Material delivery',
    'Medical examination',
    'Interview HR',
    'Technical visit',
    'Personal',
  ],
};
export const DEFAULT_DEPARTMENTS = {
  es: [
    'Producción',
    'Ingeniería',
    'Logística',
    'Mantenimiento',
    'Calidad',
    'Dirección',
    'Recursos humanos',
    'Administración',
    'Compras',
    'IT',
  ],
  en: [
    'Production',
    'Engineering',
    'Logistics',
    'Maintenance',
    'Quality',
    'Direction',
    'Human Resources',
    'Administration',
    'Sales',
    'IT',
  ],
};
export const DEFAULT_REQUIRED_FIELDS = ['nif', 'name'];

export const QUESTIONARY = [
  {
    es:
      'A día de hoy no presento o he presentado en las últimas 24 horas sintomatología compatible con COVID — 19 (tos, fiebre sensación de falta de aire, odinofagia, anosmia, ageusia, dolores musculares, diarreas, dolor torácico o cefaleas...).',
    en:
      "As of today in the last 24 hours I don't have any symptoms compatible with COVID - 19 (cough, fever, sensation of lack of the air, odynophagia, smell blindness, myalgia, loss of taste, diarrhea, angina, headache, ...).",
  },
  {
    es:
      'A día de hoy no tengo indicación de cuarentena y/o aislamiento domiciliado por parte del servicio público de salud por ser considerado un CASO SOSPECHOSO, PROBABLE O CONFIRMADO de COVID — 19.',
    en:
      'As of today I have no indication of quarantine and / or home isolation by the public health service as it is considered a SUSPICIOUS CASE, PROBABLE OR CONFIRMED COVID-19.',
  },
  {
    es:
      'A día de hoy no tengo indicación de cuarentena y/o aislamiento domiciliado por parte del servicio público de salud por ser considerado CONTACTO ESTRECHO de un CASO SOSPECHOSO, PROBABLE 0 CONFIRMADO de COVID — 19.',
    en:
      'As of today I have no indication of quarantine and / or home isolation by the public health service as it is considered a CLOSE CONTACT of a SUSPECT, PROBABLE OR CONFIRMED CASE of COVID - 19.',
  },
];

export const questionaryUserData = [
  {
    es:
      '1.-Autorizo expresamente la utilización de mis datos personales (nombre, apellidos, correo electrónico, datos de contacto…) para el envío decomunicaciones comerciales distintas de las propias del servicio voluntariamente demandado.',
    en:
      '1.-Autorizo expresamente la utilización de mis datos personales (nombre, apellidos, correo electrónico, datos de contacto…) para el envío decomunicaciones comerciales distintas de las propias del servicio voluntariamente demandado.',
  },
  {
    es:
      '2.-Autorizo expresamente la cesión de mi información personal en redes sociales (a título enunciativo Facebook, Instagram, LinkedIn..) destinada a la difusión de nuestros productos y servicios.',
    en:
      '2.-Autorizo expresamente la cesión de mi información personal en redes sociales (a título enunciativo Facebook, Instagram, LinkedIn..) destinada a la difusión de nuestros productos y servicios.',
  },
  {
    es:
      '3.-Autorizo expresamente el tratamiento de mi información para finalidades complementarias al servicio tales como participación en sorteos y/o concursos comerciales.',
    en:
      '3.-Autorizo expresamente el tratamiento de mi información para finalidades complementarias al servicio tales como participación en sorteos y/o concursos comerciales.',
  },
];

export const DEFAULT_CLIENT = {
  uid: '',
  id: '',
  name: '',
  type: '',
  reasons: DEFAULT_REASONS,
  departments: DEFAULT_DEPARTMENTS,
  requiredFields: DEFAULT_REQUIRED_FIELDS,
  hash: '',
  questionary: QUESTIONARY,
  questionaryUserData: questionaryUserData,
  theme: {
    logo: soinconLogo,
  },
  exitAuthorizations: {},
  printVisit: { enabled: false },
  devices: {},
};

export const ADMIN_VISITS_PER_PAGE = 10;
export const DNI_LENGTH_THRESHOLD = 5; // Threshold to fetch visit data from the server
