import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { useVisitForm } from 'components/Visit/visitHooks';

export const PhaseProgress = (props) => {
  const { phases, phaseIndexes } = useVisitForm();
  return (
    <Grid container spacing={1}>
      {/* - 1 because we don't want to indicate the first screen (Access) in the progress */}
      {[...Array(phases.length - 1).keys()].map((i) => (
        <Grid item xs key={i} style={{ height: 16 }}>
          <Box
            height={1}
            width={1}
            style={{ backgroundColor: '#4b4b4b', opacity: i === phaseIndexes[props.current] - 1 ? 1 : 0.2 }}
          ></Box>
        </Grid>
      ))}
    </Grid>
  );
};
