import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { useAuth } from 'components/Auth/authHooks';
import { deviceLog, LOG_EVENT_TYPES, persistUserToken } from 'utils/deviceUtils';

export const AppVersion = () => {
  const { isExternal, logout, user } = useAuth();
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  if (isExternal) return null;

  const version = process.env.REACT_APP_VERSION || '.dev';

  return (
    <Box position="absolute" top={0} left={4} color="text.disabled" fontSize={11} zIndex={1}>
      <Typography
        color="inherit"
        style={{ fontSize: 'inherit', minHeight: 30 }}
        onDoubleClick={() => {
          setIsMenuOpen(true);
        }}
        onClick={() => {
          if (isMenuOpen) {
            setIsMenuOpen(false);
          }
        }}
      >
        {isMenuOpen ? 'X' : `v${version}`}
      </Typography>

      {isMenuOpen && (
        <>
          <Button onClick={() => window.location.reload()}>Reload</Button>
          {user && (
            <Button
              onClick={() => {
                deviceLog(LOG_EVENT_TYPES.login, `Manual logout`);
                logout();
              }}
            >
              Logout
            </Button>
          )}
          {user && <Button onClick={() => persistUserToken(null)}>Reset login</Button>}
        </>
      )}
    </Box>
  );
};
