import React, { Suspense } from 'react';
import { Router } from '@reach/router';
import { CustomCircularProgress } from 'components/CustomCircularProgress/CustomCircularProgress';
import VisitScreen from 'screens/VisitScreen';
import { Login } from './Login/Login';
const Admin = React.lazy(() => import('components/Admin/Admin'));
const DeviceMonitor = React.lazy(() => import('components/Admin/DeviceMonitor'));
const Clients = React.lazy(() => import('components/Admin/Clients'));
const Employees = React.lazy(() => import('components/Admin/Employees'));
const Accesses = React.lazy(() => import('components/Admin/Accesses'));

export const AppRouter = () => {
  return (
    <Suspense fallback={<CustomCircularProgress />}>
      <Router basepath={process.env.PUBLIC_URL}>
        <Login default />
        <VisitScreen path="visit" />
        <Admin path="admin">
          <Accesses path="/" />
          <Clients path="clients" />
          <Employees path="employees" />
          <DeviceMonitor path="monitor" />
        </Admin>
      </Router>
    </Suspense>
  );
};
