export const transcriptDigits = (transcript = '') => {
  const stringNumbers = transcript
    .split(' ')
    .map((input) => {
      const number = parseInt(input);
      if (isNaN(number)) {
        return NUMBERS.findIndex((numberString) => numberString === input);
      } else {
        return number;
      }
    })
    .filter((number) => number > -1)
    .join('');
  return stringNumbers ? parseInt(stringNumbers) : undefined;
};

export const transcriptEmail = (transcript = '') => {
  // TODO: i18n
  const withoutEmail = transcript.replace('arroba', '@');
  const withoutDot = withoutEmail.replace('punto', '.');
  const withoutSpaces = removeSpaces(withoutDot);
  return withoutSpaces.toLowerCase();
};

export const removeSpaces = (transcript = '') => {
  if (Number.isInteger(transcript)) {
    return transcript;
  }
  return transcript.replace(/\s/g, '');
};

export const onlyDigits = (transcript = '') => {
  if (Number.isInteger(transcript)) {
    return transcript;
  }
  return transcript.replace(/\D/g, '');
};

export const toUpperCase = (transcript = '') => {
  return transcript.toUpperCase();
};

export const NUMBERS = ['zero', 'uno', 'dos', 'tres', 'cuatro', 'cinco', 'seis', 'siete', 'ocho', 'nueve'];
