import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Div100vh from 'react-div-100vh';

export const InvalidHash = () => {
  const classes = useStyles();

  return (
    <Div100vh style={{ justifyContent: 'center', display: 'flex' }}>
      <Typography className={classes.mobileText} color="textSecondary" variant="h5">
        Por favor, vuelva a escanear el codigo QR o utilice la terminal fija.
      </Typography>
    </Div100vh>
  );
};

const useStyles = makeStyles((theme) => ({
  mobileText: {
    margin: theme.spacing(2),
    textAlign: 'center',
  },
}));
