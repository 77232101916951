import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { useClient } from 'components/Client/clientHooks';
import { CustomDialog } from 'components/CustomDialog/CustomDialog';
import firebase from 'firebase/compat/app';

export const TYPES = {
  notAuthorised: 'notAuthorised',
  exitOk: 'exitOk',
  gdpr: 'gdpr',
};

export const ExitOk = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <CustomDialog open={props.open} onClose={props.onClose} primaryButtonText="Cerrar" onPrimaryClick={props.onClose}>
      <Typography className={classes.dialogText} variant="h5">
        {t('goodbye')}
      </Typography>
    </CustomDialog>
  );
};

export const ExitNotAuthorised = (props) => {
  const { t } = useTranslation();
  const { queryRef } = useClient();
  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState(false);
  const { onAuthorized } = props;
  const hiddenInput = React.useRef();
  const { client } = useClient();

  const refocusHiddenInput = React.useCallback(() => {
    if (!hiddenInput || !hiddenInput.current) return;
    hiddenInput.current.focus();
  }, []);

  const onClose = () => {
    hiddenInput.current = null;
    setIsLoading(false);
    props.onClose();
  };

  const authorizeExit = () => {
    if (isLoading) return;
    setIsLoading(true);
    setTimeout(async () => {
      // Need to use setTimout becuase the RFID reader "writes" in the input and triggers several onChange events. So we wait until the input is fully written.
      await queryRef
        .collection('accesses')
        .doc(props.accessId)
        .update({
          exitAuthorization: {
            type: 'rfid',
            id: hiddenInput.current.value,
            createdTime: firebase.firestore.FieldValue.serverTimestamp(),
          },
        });
      onClose();
      onAuthorized();
    }, 50);
  };

  return (
    <CustomDialog open={props.open} onClose={onClose} primaryButtonText={t('close')} onPrimaryClick={onClose}>
      <Box marginX={2}>
        <Box marginY={5}>
          <Typography variant="h4" align="center">
            {t('exitNotAuthorized')}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <Box display="inline-block">
            <Typography className={classes.dialogText} variant="h6">
              {t('exitAuthorizationOptions')}:
            </Typography>
            <ul style={{ width: 0, minWidth: '100%' }}>
              {client.exitAuthorizations.rfid && (
                <li>
                  <Typography>
                    <Box fontWeight="fontWeightLight" component="span">
                      {t('exitAuthorizationOptionScan')}
                    </Box>
                  </Typography>
                </li>
              )}
              <li>
                <Typography>
                  <Box fontWeight="fontWeightLight" component="span">
                    {t('exitAuthorizationOptionAdmin')}
                  </Box>
                </Typography>
              </li>
            </ul>
          </Box>
        </Box>
        {client.exitAuthorizations.rfid && (
          <input
            style={{ position: 'absolute', top: -100 }}
            onChange={(e) => {
              authorizeExit();
            }}
            autoFocus
            onBlur={() => {
              refocusHiddenInput();
            }}
            ref={hiddenInput}
          />
        )}
      </Box>
    </CustomDialog>
  );
};

export const Gdpr = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <CustomDialog
      open={props.open}
      onClose={props.onClose}
      primaryButtonText={t('close')}
      onPrimaryClick={props.onClose}
    >
      <Box display="flex" justifyContent="center" marginX={2}>
        <Box display="inline-block">
          <Typography className={classes.dialogText} variant="h6">
            {t('gdprTitle')}
          </Typography>
          <ol style={{ width: 0, minWidth: '100%' }}>
            <li>
              <Box fontWeight="fontWeightLight">
                <Typography style={{ fontWeight: 'inherit' }}>{t('gdprCondition1')}</Typography>
              </Box>
            </li>
            <li>
              <Box>
                <Typography style={{ fontWeight: 'inherit' }}>{t('gdprCondition2')}</Typography>
              </Box>
            </li>
          </ol>
        </Box>
      </Box>
    </CustomDialog>
  );
};

const useStyles = makeStyles((theme) => ({
  buttonDialog: {
    border: 0,
    borderRadius: 0,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 50,
    fontSize: 10,
  },

  dialog: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialogContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: theme.spacing(1),
  },
  dialogText: {
    margin: theme.spacing(2),
    textAlign: 'center',
  },
}));
