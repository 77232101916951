import React from 'react';
import { useLocation, navigate } from '@reach/router';
import { captureException, setUser } from '@sentry/react';
import { useLocalStorage } from 'react-use';
import { InvalidHash } from 'components/Login/InvalidHash';
import { deviceLog, LOG_EVENT_TYPES, persistUserToken } from 'utils/deviceUtils';
import { au, fn } from '../../firebase';
import { CustomCircularProgress } from '../CustomCircularProgress/CustomCircularProgress';

const setSentryUser = setUser;

export const AuthContext = React.createContext();

const search = window.location.search;
const params = new URLSearchParams(search);
const hash = params.get('hash');

export const AuthProvider = (props) => {
  const { pathname } = useLocation();
  const [user, setUser] = React.useState();
  const [isValidatingHash, setIsValidatingHash] = React.useState(false);
  const [isHashInvalid, setHashInvalid] = React.useState(false);
  const [clientId, setClientId] = React.useState(null);
  const [isExternal, setIsExternal] = React.useState();
  const [isAdmin, setIsAdmin] = React.useState();
  const [error, setError] = React.useState(false);
  const [shadowClientId, setShadowClientId, removeShadowClientId] = useLocalStorage('vilo-shadowClientId');

  const logout = React.useCallback(() => {
    setUser(null);
    au.signOut();
  }, []);

  const login = React.useCallback(async (user, password) => {
    try {
      await au.signInWithEmailAndPassword(user, password);
      return { result: true };
    } catch (error) {
      return { error };
    }
  }, []);

  const loginWithToken = React.useCallback(async (token) => {
    if (!token) return;
    try {
      await au.signInWithCustomToken(token);
      return { result: true };
    } catch (error) {
      captureException(error, {
        extra: { token },
      });
      persistUserToken('');
      return { error };
    }
  }, []);

  const handleAuthStateChanged = React.useCallback(async (firebaseUser) => {
    deviceLog(LOG_EVENT_TYPES.login, `Logged: ${!!firebaseUser}`);
    setUser(firebaseUser);
    if (!!firebaseUser) {
      setSentryUser({ id: firebaseUser.uid, email: firebaseUser.email });
      setIsValidatingHash(false);
      const tokenResult = await firebaseUser.getIdTokenResult();
      let claims = tokenResult.claims;
      if (!tokenResult.claims.clientId) {
        await firebaseUser.getIdToken(true);
        const tokenResult = await firebaseUser.getIdTokenResult();
        claims = tokenResult.claims;
      }
      if (!claims.clientId) {
        setError(`Missing clientId in user claims for UID: ${firebaseUser.uid}, email: "${firebaseUser.email}"`);
        return;
      }
      setIsExternal(claims.isExternal);
      setIsAdmin(claims.role === 'admin');
      setClientId(claims.clientId);
    }
  }, []);

  // Login by hash
  React.useEffect(() => {
    if (!hash) return;
    (async () => {
      // Here might be some better message instead of the loader because the cold start might take a while
      setIsValidatingHash(true);
      const handleHash = fn.httpsCallable('handleHash');
      try {
        const { data } = await handleHash({ hash });
        const { error } = data;
        if (error) {
          if (error === 'No hash found') {
            setHashInvalid(true);
            setIsValidatingHash(false);
          } else {
            captureException(error, {
              extra: {
                customMessage: 'Error during hash validation',
                capturedIn: 'error data',
              },
            });
          }
          return;
        }
        loginWithToken(data.token);
      } catch (error) {
        console.error(error);
        captureException(error, {
          extra: {
            customMessage: 'Error during hash validation',
            capturedIn: 'catch',
          },
        });
        setError(error);
      }
    })();
  }, [loginWithToken]);

  React.useEffect(() => {
    // Attach auth observer on mount
    au.onAuthStateChanged(handleAuthStateChanged);
  }, [handleAuthStateChanged]);

  React.useEffect(() => {
    // Redirect to /login if user is not logged
    if (user !== null) return; // null means "not logged". undefined means "not initialised"
    if (pathname.includes('/login')) return;
    navigate(`${process.env.PUBLIC_URL}/login`, { state: { redirect: pathname } });
  }, [pathname, user]);

  const shadowLogin = {
    isShadowLogin: !!shadowClientId,
    login: React.useCallback(
      (shadowClient) => {
        if (!isAdmin) return;
        setShadowClientId(shadowClient.id);
        setClientId(shadowClient.id);
      },
      [setShadowClientId, isAdmin]
    ),
    abort: React.useCallback(async () => {
      const tokenResult = await user.getIdTokenResult();
      const claims = tokenResult.claims;
      removeShadowClientId();
      setClientId(claims.clientId);
    }, [removeShadowClientId, user]),
  };

  if (error) {
    throw new Error(error);
  }

  const canRenderChildren = (user && clientId) || pathname.includes('/login'); // Render children only with Firebase user or on /login

  if (!canRenderChildren || isValidatingHash) {
    return <CustomCircularProgress />;
  }

  if (isHashInvalid) {
    return <InvalidHash />;
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        login,
        logout,
        clientId: shadowClientId || clientId,
        isExternal,
        isAdmin,
        shadowLogin,
        loginWithToken,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};
