export const getHash = (length = 5) => {
  for (var a = '', b = 36; a.length < length; ) {
    a += ((Math.random() * b) | 0).toString(b);
  }
  return a;
};

// https://ui.dev/validate-email-address-javascript/
export const emailIsValid = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

// https://stackoverflow.com/a/37511463/177079
export const removeSpecialChars = (transcript = '') => {
  return transcript.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

// https://github.com/jquense/yup/issues/298#issuecomment-559017330
export const emptyStringToNull = (value, originalValue) => {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
};
