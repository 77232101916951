import { fn } from 'firebase.js';

export const fetchProxy = async (url, options) => {
  const proxy = fn.httpsCallable('proxy');
  try {
    const { data: response } = await proxy({ url, options });
    return { status: 200, data: response };
  } catch (e) {
    throw e;
  }
};
