import { captureException } from '@sentry/react';
import { db } from 'firebase.js';
import { throttle } from 'lodash';
import firebase from 'firebase/compat/app';
import { isObjectEmpty } from 'utils/objectUtils';
import { getHash } from 'utils/stringUtils';

export const createTicket = () => {
  const ascii2Hex = (str) => {
    const arr1 = [];
    for (let n = 0, l = str.length; n < l; n++) {
      const hex = Number(str.charCodeAt(n)).toString(16);
      arr1.push(hex.toUpperCase());
    }
    return arr1.join('');
  };
  const command = '^TS003^FF';
  return ascii2Hex(command);
};

export const printViaBluetooth = (data, mac) => {
  if (!mac) {
    console.error('No MAC address specified');
    return;
  }
  if (!window.KBBluetooth) return;
  const connectToPrinter = () => {
    if (window.KBBluetooth) {
      window.KBBluetooth.connectTo(mac);
    }
  };
  if (!window.KBBluetooth.isConnected()) {
    connectToPrinter();
    setTimeout(() => {
      printViaBluetooth(data);
    }, 100);
    return;
  }
  console.log('🛎 ', 'printing', data);
  window.KBBluetooth.sendHexString(data);
  // window.KBBluetooth.disconnect();
};

let logs = {};
export const deviceLog = (eventType, details) => {
  if (window.Cypress) return;
  const kioskSdk = getKioskSdk();
  if (!kioskSdk) return;
  const deviceId = kioskSdk.getUniqueIdentifier();
  const version = process.env.REACT_APP_VERSION || 'dev';
  if (!deviceId) return;
  const kioskTitle = kioskSdk.getKioskTitle();

  const log = {
    version,
    createdTime: new Date(),
    createdTimeServer: firebase.firestore.FieldValue.serverTimestamp(),
    href: window.location.href,
    kioskTitle,
    deviceId,
    isCharging: kioskSdk.isCharging(),
    batteryLevel: Math.round(kioskSdk.getBatteryLevel()),
    isOnline: window.navigator.onLine,
    wifiStrength: kioskSdk.getWiFiSignal(),
    isScreenOn: kioskSdk.isScreenOn(),
    eventType,
    ...(details && { details }),
  };

  logs = {
    ...logs,
    [getHash(16)]: log,
  };
  flushLogs();
};

const flushLogs = throttle(
  async () => {
    if (isObjectEmpty(logs)) return;
    try {
      const query = db.doc(`logs/${getDateKey()}`).set(logs, { merge: true });
      logs = {};
      await query;
    } catch (error) {
      captureException(error);
    }
  },
  3000,
  { leading: false, trailing: true }
);

export const getDateKey = () => {
  const now = new Date();
  const year = now.getFullYear() + '';
  const month = (now.getMonth() + 1 + '').padStart(2, '0');
  const day = (now.getDate() + '').padStart(2, '0');
  return [year, month, day].join('');
};

export const filterLogs = (filter) => (log) => {
  if (filter.eventTypes && !filter.eventTypes.includes(log.eventType)) {
    return false;
  }
  if (filter.deviceId && filter.deviceId !== log.deviceId) {
    return false;
  }
  return true;
};

export const filterByDeviceId = (id) => (log) => {
  return id === log.deviceId;
};

export const sortLogsByCreatedTime = (logs) => {
  return [...logs].sort((a, b) => {
    if (a.createdTime === b.createdTime) return 0;
    return a.createdTime > b.createdTime ? -1 : 1;
  });
};

export const sanitizeLogs = (logs) => {
  return Object.values(logs || {}).reduce((result, log) => {
    return [
      ...result,
      {
        ...log,
        createdTime: log.createdTime.seconds * 1000,
      },
    ];
  }, []);
};

export const getKioskSdk = () => {
  // return kioskDevSdk;
  return window.Android;
};

/**
 * For dev purposes
 */
// const kioskDevSdk = {
//   getUniqueIdentifier: () => 'fooDeviceUid',
//   getKioskTitle: () => 'someDeviceTitle',
//   getHomePage: () => 'https://app.registrodevisitas.es',
//   isCharging: () => true,
//   getBatteryLevel: () => 100,
//   getWiFiSignal: () => 5,
//   isScreenOn: () => true,
//   putStringValue: (key, value) => window.localStorage.setItem(key, value),
//   getStringValue: (key) => window.localStorage.getItem(key),
//   clearSession: () => console.log('🛎 ', 'cleaning cache'),
// };

export const PING_LOG_INTERVAL = 10 * 60_000; // 10 minutes

export const LOG_EVENT_TYPES = {
  start: 'start',
  render: 'render',
  ping: 'ping',
  resetPhase: 'resetPhase',
  onResult: 'onResult',
  searchPrefill: 'searchPrefill',
  resetPrefill: 'resetPrefill',
  printVisit: 'printVisit',
  phoneCall: 'phoneCall',
  retry: 'retry',
  saveVisit: 'saveVisit',
  token: 'storedToken',
  login: 'login',
};

const APP_URL = 'https://app.registrodevisitas.es';
const APP_URL_PRE = 'https://visitors-log-pre-ebdd9.web.app';

export const verifyDeviceConfig = () => {
  const kioskSdk = getKioskSdk();
  if (!kioskSdk) return;
  if (!kioskSdk.getHomePage().includes(APP_URL) && !kioskSdk.getHomePage().includes(APP_URL_PRE)) {
    // Skip when developing
    if (!kioskSdk.getHomePage().includes('http://192') && !kioskSdk.getHomePage().includes('http://172')) {
      kioskSdk.setHomePage(APP_URL);
    }
  }
};

export const persistUserToken = (token) => {
  const kioskSdk = getKioskSdk();
  if (!kioskSdk) return;
  deviceLog(LOG_EVENT_TYPES.token, token ? 'saved' : 'removed');
  kioskSdk.putStringValue('authToken', token || '');
};

export const getUserToken = () => {
  const kioskSdk = getKioskSdk();
  if (!kioskSdk) return;
  return kioskSdk.getStringValue('authToken');
};
