import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle, IconButton, makeStyles, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

export const CustomDialog = (props) => {
  const classes = useStyles();

  return (
    <Dialog className={classes.dialog} open={props.open} onClose={props.onClose}>
      {(props.title || props.closable) && (
        <DialogTitle disableTypography style={{ display: 'flex' }}>
          {props.title && (
            <Typography variant="h6" style={{ flexGrow: 1 }}>
              {props.title}
            </Typography>
          )}
          {props.closable && (
            <IconButton aria-label="close dialog" onClick={props.onClose} className={classes.closeButton}>
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
      )}
      <div className={classes.dialogContent}>{props.children}</div>
      {(props.onPrimaryClick || props.primaryButtonRef) && (
        <DialogActions>
          <Button
            className={classes.buttonDialog}
            color="primary"
            variant="contained"
            disableElevation
            disabled={props.isLoading}
            aria-label={props.primaryButtonText}
            {...(props.onPrimaryClick && { onClick: props.onPrimaryClick })}
            {...(props.primaryButtonRef && { ref: props.primaryButtonRef })}
          >
            {props.primaryButtonText}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    marginTop: -theme.spacing(1),
    marginRight: -theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialog: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialogContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: theme.spacing(2),
  },
}));
