import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { useAuth } from 'components/Auth/authHooks';
import { useClient } from 'components/Client/clientHooks';

export const ShadowLoginBar = (props) => {
  const { client } = useClient();
  const { shadowLogin } = useAuth();
  return (
    <Box
      position="fixed"
      display="flex"
      top={0}
      paddingY={1}
      paddingX={1}
      width={1}
      style={{ backgroundColor: 'lightblue', opacity: 0.8 }}
      alignItems="center"
    >
      <Typography display="inline" style={{ flexGrow: 1 }}>{`You are logged as "${client.name}"`}</Typography>
      <Button onClick={shadowLogin.abort} variant="outlined" style={{ justifySelf: 'flex-end' }}>
        Abort
      </Button>
    </Box>
  );
};
