export const PROJECT_ID = 'visitors-log-app';

export const config = {
  production: {
    apiKey: 'AIzaSyCvTFU0jcvEfd2RzrgHdJKQK9ij1_kFX8s',
    authDomain: 'visitors-log-app.firebaseapp.com',
    databaseURL: 'https://visitors-log-app.firebaseio.com',
    projectId: PROJECT_ID,
    storageBucket: 'visitors-log-app.appspot.com',
    messagingSenderId: '167725638220',
    appId: '1:167725638220:web:b5d4672ef7169c73748ae9',
    measurementId: 'G-LGCDWD2PRW',
  },
  pre: {
    apiKey: 'AIzaSyAGIzD4A8XYO0iGqIjGNl003EOExsKyrbw',
    authDomain: 'visitors-log-pre-ebdd9.firebaseapp.com',
    projectId: 'visitors-log-pre',
    storageBucket: 'visitors-log-pre.appspot.com',
    messagingSenderId: '1082081137492',
    appId: '1:1082081137492:web:f0125ae8725b9fa85a6ec1',
  },
};
