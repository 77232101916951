import React from 'react';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MicIcon from '@material-ui/icons/Mic';
import MicNoneIcon from '@material-ui/icons/MicNone';

export const SpeechRecognitionButton = (props) => {
  const isActive = props.isActive;
  const classes = useVoiceToTextStyles({ isActive });

  return (
    <IconButton
      aria-label="start talking"
      onMouseDown={(e) => {
        props.onPress();
        e.preventDefault();
      }}
      classes={classes}
    >
      {isActive ? <MicIcon /> : <MicNoneIcon />}
    </IconButton>
  );
};

const useVoiceToTextStyles = makeStyles((theme) => ({
  root: ({ isActive }) => ({
    color: isActive ? theme.palette.text.primary : theme.palette.input.contrastText,
  }),
}));
