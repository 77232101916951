const SESSION_REPLAY_START_HOUR = 6;
const SESSION_REPLAY_END_HOUR = 22;
const INTERVAL_TICK = 10 * 60_000; // Interval to check the clock
let tracker;
let interval;

export const init = (clientId) => {
  import('@asayerio/tracker').then((Tracker) => {
    tracker = new Tracker.default({
      projectID: 8861992207480143,
      obscureInputEmails: false,
      obscureTextEmails: false,
      obscureTextNumbers: false,
    });
    tracker.userID(clientId);
    scheduledRun();
  });
};

const scheduledRun = () => {
  if (interval) return;
  startStop();
  interval = setInterval(startStop, INTERVAL_TICK);
};

const startStop = () => {
  if (!tracker) return;
  const now = new Date();
  const hour = now.getHours();
  if (hour >= SESSION_REPLAY_START_HOUR && hour <= SESSION_REPLAY_END_HOUR) {
    !tracker.active() && tracker.start();
  } else {
    tracker.active() && tracker.stop();
  }
};
