import React from 'react';
import { Box, Container, Grid, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useTranslation } from 'react-i18next';
import { MainButton } from 'components/Button/MainButton';
import { Clock } from 'components/Clock/Clock';
import { useKeyboard } from 'components/CustomKeyboard/keyboardHooks';
import { PhaseProgress } from 'components/PhaseProgress/PhaseProgress';
import { useVisitForm } from 'components/Visit/visitHooks';

export const Phase = React.forwardRef((props, ref) => {
  const { visitForm, errorTypes } = useVisitForm();
  const theme = useTheme();
  const backButtonClasses = useBackButtonStyles();
  const { t } = useTranslation();
  const centeredRef = React.useRef(null);
  const nif = visitForm.watch('nif');
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const keyboard = useKeyboard() || {};

  return (
    <Container
      maxWidth={props.maxWidth ? props.maxWidth : 'md'}
      style={{
        height: isXs && !props.fullHeight ? 'initial' : `calc(100vh - ${keyboard.height || 0}px)`,
        maxHeight: '100%',
        paddingTop: theme.spacing() * 1,
      }}
      ref={ref}
    >
      <Grid container direction="column" style={{ height: '100%' }}>
        {/* header shown only on mobile */}
        {isXs && (
          <Grid item container xs={12} alignItems="center" style={{ flexBasis: 'unset' }}>
            {props.back && (
              <Grid item xs={1}>
                <Button
                  disableElevation
                  variant="contained"
                  onClick={props.back}
                  style={{ height: '100%', maxWidth: '100%', minWidth: 'unset' }}
                  classes={backButtonClasses}
                  fullWidth
                  aria-label={t('back')}
                >
                  <ArrowBackIosIcon />
                </Button>
              </Grid>
            )}
            <Grid item xs>
              <Typography display="inline" style={{ textTransform: 'uppercase' }}>
                <Box fontWeight="fontWeightLight" display="inline" component="span" color="grey.500" paddingLeft={1}>
                  {t('visitor')}&nbsp;
                </Box>
              </Typography>
              <Typography display="inline">{nif}</Typography>
              <Typography style={{ textTransform: 'uppercase' }}>
                <Box fontWeight="fontWeightLight" color="grey.500" paddingLeft={1} component="span">
                  <Clock />
                </Box>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <PhaseProgress current={props.phase} />
            </Grid>
          </Grid>
        )}

        {!isXs && (
          <>
            {/* Progress */}
            <Grid container item spacing={1}>
              <Grid item style={{ width: 72 }}></Grid>
              <Grid item xs>
                <Box marginBottom={1}>
                  <PhaseProgress current={props.phase} />
                </Box>
              </Grid>
              <Grid item style={{ width: 72 }}></Grid>
            </Grid>

            {/* nif and date */}
            <Grid container item spacing={1}>
              <Grid item style={{ width: 72 }}></Grid>
              <Grid item xs>
                <Typography display="inline" style={{ textTransform: 'uppercase' }}>
                  <Box fontWeight="fontWeightLight" display="inline" component="span" color="grey.500">
                    {t('visitor')}&nbsp;
                  </Box>
                </Typography>
                <Typography display="inline">{nif}</Typography>
              </Grid>
              <Grid item xs style={{ textAlign: 'end' }}>
                <Typography display="inline" style={{ textTransform: 'uppercase' }}>
                  <Box fontWeight="fontWeightLight" component="span" color="grey.500">
                    <Clock />
                  </Box>
                </Typography>
              </Grid>
              <Grid item style={{ width: 72 }}></Grid>
            </Grid>
          </>
        )}

        <Grid
          container
          spacing={1}
          ref={centeredRef}
          style={{ flexGrow: 1, maxHeight: (!isXs && props.maxHeight) || 'initial' }}
        >
          {/* back button */}
          {!isXs && props.back && (
            <Grid container item spacing={1} style={{ width: 80 }}>
              <Grid item style={{ width: '100%' }}>
                <Button
                  disableElevation
                  variant="contained"
                  onClick={props.back}
                  style={{ height: '100%', maxWidth: '100%', minWidth: 'unset' }}
                  classes={backButtonClasses}
                  fullWidth
                  aria-label={t('back')}
                >
                  <ArrowBackIosIcon />
                </Button>
              </Grid>
            </Grid>
          )}

          {props.children}

          {/* next button on wide */}
          {!isXs && props.next && (
            <Grid container item spacing={1} style={{ width: 80 }}>
              <Grid item style={{ width: '100%' }}>
                <Button
                  disableElevation
                  variant="contained"
                  onClick={props.next}
                  style={{ height: '100%', maxWidth: '100%', minWidth: 'unset' }}
                  fullWidth
                  aria-label={t('next')}
                  color={props.canNext ? 'primary' : 'default'}
                  {...(props.understood && { disabled: props.understood })}
                >
                  <ArrowForwardIosIcon />
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>

        {/* errors & link */}
        <Grid container item spacing={1}>
          {!isXs && <Grid item style={{ width: 72 }}></Grid>}
          <Grid item xs style={{ position: 'relative' }}>
            <Box position={isXs ? 'initial' : 'absolute'} width={1}>
              {errorTypes
                .filter((error) => {
                  const errorExists = Object.values(visitForm.errors).find((fieldError) =>
                    error.types.includes(fieldError.type)
                  );
                  return !!errorExists;
                })
                .map((error, i) => (
                  <Typography color="error" key={error.types[0]}>
                    <Box fontWeight="fontWeightLight" component="span">
                      {[...Array(i + 1).keys()].map(() => '\u2009*')} {error.text}
                    </Box>
                  </Typography>
                ))}
            </Box>
          </Grid>
          <Grid item xs="auto" style={{ textAlign: 'end' }}>
            <Typography display="inline">
              <Box fontWeight="fontWeightLight" component="span" color="grey.500">
                www.registrodevisitas.es
              </Box>
            </Typography>
          </Grid>

          {/* next button on mobile */}
          {props.next && isXs && (
            <Grid item xs={12}>
              <MainButton
                disableElevation
                color="primary"
                variant="contained"
                fullWidth
                onClick={props.next}
                aria-label={t('next')}
              >
                {t('next')}
              </MainButton>
            </Grid>
          )}

          {!isXs && <Grid item style={{ width: 72 }}></Grid>}
        </Grid>
      </Grid>
    </Container>
  );
});

const useBackButtonStyles = makeStyles((theme) => ({
  label: {
    paddingLeft: 10,
  },
}));
