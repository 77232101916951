import React from 'react';
import { TextField, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from 'components/Auth/authHooks';
import { useKeyboard } from 'components/CustomKeyboard/keyboardHooks';
import { SpeechRecognitionButton } from 'components/SpeechRecognition/SpeechRecognitionButton';
import { useSpeechRecognition } from 'components/SpeechRecognition/speechRecognitionHooks';
import './customTextField.css'; // I don't know how how to add these rules via components props

const CustomTextField = function CustomTextField(props) {
  const inputRef = React.useRef();
  const { isExternal } = useAuth();
  const {
    className,
    voiceToText,
    onChange,
    label,
    errorNumber,
    required,
    transformations,
    inputRef: inputRef_,
    setOpen,
    open,
    id,
    ...otherProps
  } = props;
  const isVoiceToTextAvailable = voiceToText && !isExternal;
  const labelClasses = useLabelStyles();
  const keyboard = useKeyboard();
  const transformationsRef = React.useRef(transformations || []);
  const transform = React.useCallback(
    (input = '') => {
      return transformationsRef.current.reduce((result, fn) => fn(result), input);
    },
    [transformationsRef]
  );
  const onChangeInternal = React.useCallback(
    (input, isSw) => {
      const text = transform(input);
      onChange(text);
      if (setOpen && id === 'employeeName') {
        if (text.length > 2) {
          setOpen(true);
        } else {
          setOpen(false);
        }
      }
      if (isSw && inputRef.current && keyboard.keyboardRef.current.caretPosition !== null) {
        // This syncronizes position of the cursor between native and SW keyboard when selecting it's position in the text field
        inputRef.current.setSelectionRange(
          keyboard.keyboardRef.current.caretPosition,
          keyboard.keyboardRef.current.caretPosition
        );
        if (keyboard.keyboardRef.current.caretPosition === text.length) {
          // Scroll to the end of the input when writing at the end. Without this the newly added letter are not visible (you would have to scroll manually in the input to the end).
          inputRef.current.scrollLeft = 99999;
        }
      }
    },
    [onChange, transform, keyboard.keyboardRef]
  );
  const speechRecognition = useSpeechRecognition({
    onResult: (result) => {
      const transcriptFunctions = voiceToText?.onTranscript || [];
      const value = transcriptFunctions.reduce((result, fn) => fn(result), result);
      const transformed = transform(value);
      onChange(transformed);
      if (setOpen && id === 'employeeName') {
        if (transformed.length > 2) {
          setOpen(true);
        } else {
          setOpen(false);
        }
      }
      keyboard.setValue(transformed);
    },
  });
  const classes = useStyles({ isSpeechRecognitionActive: speechRecognition.listening, isVoiceToTextAvailable });
  const labelWithAsterisks =
    props.error && errorNumber ? (
      <Typography display="inline">
        {label} {[...Array(errorNumber).keys()].map(() => '\u2009*')}
      </Typography>
    ) : (
      `${label}${required ? ' \u2009*' : ''}`
    );

  return (
    <TextField
      margin="dense"
      autoComplete="off"
      value={props.value}
      type={'text'}
      fullWidth
      spellCheck="false"
      variant="filled"
      ref={props.innerRef}
      inputRef={(ref) => {
        inputRef.current = ref;
        if (props.inputRef) {
          props.inputRef.current = ref;
        }
      }}
      label={labelWithAsterisks}
      {...otherProps}
      inputProps={{
        ...(props.label && { 'aria-label': props.label }),
        ...(otherProps.inputProps || {}),
      }}
      InputProps={{
        ...(otherProps.InputProps || {}),
        startAdornment:
          (document.activeElement === inputRef.current || props.value) &&
          otherProps.InputProps &&
          otherProps.InputProps.startAdornment &&
          otherProps.InputProps.startAdornment(),
        endAdornment: (
          <>
            {(document.activeElement === inputRef.current || props.value) &&
              otherProps.InputProps &&
              otherProps.InputProps.endAdornment &&
              otherProps.InputProps.endAdornment()}
            {isVoiceToTextAvailable && (
              <SpeechRecognitionButton
                name={props.name}
                onPress={() => {
                  if (speechRecognition.listening) {
                    speechRecognition.stop();
                  } else {
                    setTimeout(() => {
                      inputRef.current && inputRef.current.focus();
                      speechRecognition.listen({ lang: 'es-ES' });
                    }, 0);
                  }
                }}
                isActive={speechRecognition.listening}
              />
            )}
          </>
        ),
        onFocus: () => {
          keyboard.setOnChange(onChangeInternal);
          keyboard.setValue(props.value);
        },
        onBlur: () => {
          keyboard.setOnChange(null);
          speechRecognition.stop();
          props.InputProps && props.InputProps.onBlur && props.InputProps.onBlur();
        },
        disableUnderline: true,
        classes: classes,
      }}
      onFocusCapture={() => {
        if (setOpen && open && id !== 'employeeName') {
          setOpen(false);
        }
      }}
      InputLabelProps={{ classes: labelClasses }}
      onChange={(e) => {
        // While typing on hardware keyboard
        const transformed = transform(e.target.value);
        keyboard.setValue(transformed);
        onChange(transformed);
        if (setOpen && id === 'employeeName') {
          if (transformed.length > 2) {
            setOpen(true);
          } else {
            setOpen(false);
          }
        }
      }}
      error={!!props.error}
      helperText={props.helperText}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  root: ({ isSpeechRecognitionActive }) => ({
    fontSize: 20,
    backgroundColor: theme.palette.input.main,
    color: theme.palette.input.contrastText,
    '&.Mui-focused,&:hover': {
      backgroundColor: isSpeechRecognitionActive ? theme.palette.error.dark : theme.palette.input.main,
    },
  }),
  adornedEnd: ({ isVoiceToTextAvailable }) => ({
    paddingRight: isVoiceToTextAvailable ? 0 : theme.spacing(1),
  }),
}));

const useLabelStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.input.hint,
    '&.Mui-focused': {
      color: theme.palette.input.hint,
    },
  },
  focused: {
    color: theme.palette.input.main,
  },
}));

export default React.forwardRef((props, ref) => <CustomTextField {...props} innerRef={ref} />);
