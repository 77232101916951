import React from 'react';
import { db } from 'firebase.js';
import { useClient } from 'components/Client/clientHooks';
import { getKioskSdk, persistUserToken } from 'utils/deviceUtils';

export const DeviceControl = () => {
  const { client } = useClient();

  React.useEffect(() => {
    registerDevice(client);
  }, [client]);

  const { resetUserToken } = client;
  // Remove stored token via DB change
  React.useEffect(() => {
    if (resetUserToken) {
      persistUserToken('');
    }
  }, [resetUserToken]);

  return null;
};

const registerDevice = (client) => {
  if (!client) return;
  const kioskSdk = getKioskSdk();
  if (!kioskSdk) return;
  const deviceId = kioskSdk.getUniqueIdentifier();
  if (client.devices[deviceId]) return;
  const device = {
    [deviceId]: {
      title: kioskSdk.getKioskTitle(),
    },
  };
  db.doc(`clients/${client.id}`).set({ devices: device }, { merge: true });
};
