import React from 'react';
import { db } from 'firebase.js';
import moment from 'moment';
import { useClient } from 'components/Client/clientHooks';
import firebase from 'firebase/compat/app';
import { getKioskSdk } from 'utils/deviceUtils';

export const useCleanCache = () => {
  const { client } = useClient();

  return React.useCallback(() => {
    if (!client.cleanCacheDate) return;
    const cleanCacheDate = client.cleanCacheDate.seconds * 1000;
    if (moment(cleanCacheDate).isAfter(moment())) return;
    const kioskSdk = getKioskSdk();
    // https://help.android-kiosk.com/en/article/js-browser-functions-nwkfln/#6-clear-session
    const clearCache = true;
    const clearCookies = true;
    const clearForms = true;
    const clearWebStorage = false;
    kioskSdk.clearSession(clearCache, clearCookies, clearForms, clearWebStorage);
    db.doc(`clients/${client.id}`).update({ cleanCacheDate: firebase.firestore.FieldValue.delete() });
  }, [client]);
};
