import React from 'react';
import { KeyboardContext } from 'components/CustomKeyboard/KeyboardContext';

export const useKeyboard = (options = {}) => {
  const context = React.useContext(KeyboardContext);

  React.useEffect(() => {
    if (options.layout) {
      context.setLayout(options.layout);
    }
  }, [context, options.layout]);

  return context;
};
