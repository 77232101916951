import React from 'react';
import { useVisitForm } from 'components/Visit/visitHooks';

let timeoutID;
const TIMEOUT = 3 * 60 * 1000; // 3 minutes

export const Timer = (props) => {
  const { onReset } = props;
  const { visitForm, defaultValues } = useVisitForm();
  const values = visitForm.watch();
  const resetTimer = React.useCallback(() => {
    if (timeoutID) {
      window.clearTimeout(timeoutID);
    }
  }, []);

  const startTimer = React.useCallback(() => {
    resetTimer();
    // Reset the input after 15 seconds of inactivity
    timeoutID = window.setTimeout(() => {
      if (!values.nif) return; // do not reset on the initial page
      visitForm.reset(defaultValues);
      onReset();
    }, TIMEOUT);
  }, [resetTimer, values, visitForm, onReset, defaultValues]);

  React.useEffect(() => {
    startTimer();
    return resetTimer;
  }, [startTimer, resetTimer]);

  return null;
};
